import React, { Component,useRef,useEffect } from "react"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Button,
  Row, Col, Card
} from "reactstrap"

// useEffect(() => { 
//   alert('fds')
//   pauseVideo();
// }, [this.state.activeIndex]);

class VideoLink extends Component {

  constructor(props) {
    super(props)
    this.iframeRef = React.createRef();
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
    this.setState(props.Videolink)
  }

  onExiting() {

    this.animating = true
    this.pauseVideo()
  }


  pauseVideo = () => {

   var videoLinks =  this.props.Videolink;
   for(var i=0;i<videoLinks.length;i++)

   {
    if(i === (this.state.activeIndex))
    {
      var iframe = document.getElementById(`video-${this.props.Videolink[this.state.activeIndex].gsvid}`);
      // Option 1: Set iframe source to an empty string
      iframe.src ="https://www.youtube.com/embed/"+videoLinks[i].gsv_link;
    }
    else{
      var iframe =document.getElementById(`video-${this.props.Videolink[i].gsvid}`);
      // Option 1: Set iframe source to an empty string
      iframe.src = '';
    }
   }


    // var iframe = document.getElementById('videoFrame');
    // // Option 1: Set iframe source to an empty string
    // iframe.src = '';
    // // Accessing the iframe's contentWindow
    // const iframe = this.iframeRef.current;
    // if (iframe) {
    //   const iframeContentWindow = iframe.contentWindow;
    //   if (iframeContentWindow) {
    //     document.getElementsByTagName("video").pause();
    //   //  document.getElementById('player').pau
    //     // Find the video element inside the iframe
    //     // const videoElement = iframeContentWindow.document.querySelector('video');
    //     // if (videoElement) {
    //     //   // Pause the video
    //     //   videoElement.pause();
    //     // }
    //   }
    // }
  };

  onExited() {
    this.animating = false
  }

  next() {
console.log(this.props.Videolink)
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === this.props.Videolink.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.Videolink.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }



  render() {
    const { activeIndex } = this.state


    const slides = this.props.Videolink.map((item, index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.gsv_link}
        >


          <iframe className="iframeSize"
            src=    {"https://www.youtube.com/embed/"+item.gsv_link }
            allow='autoplay; encrypted-media'
            width="80%"
            height="400px"
            allowfullScreen
            ref={this.iframeRef}
            title='video'
            id={`video-${item.gsvid}`} 
          />
          {/* <CarouselCaption
            captionText={item.gsv_text}
            // captionHeader={item.caption}
            className="carousel-caption d-none d-md-block"
          /> */}
        <p style={{textAlign:'center'}}>{item.gsv_text} </p>
        </CarouselItem>
      )
    })

    return (
      <React.Fragment>
      
        <Row>

        <div className = "videoCarousel">
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          dark={true}
          interval={false}
          // onExiting={this.handleExiting}
        >
          <CarouselIndicators
            items={this.props.Videolink}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
        </div>
        </Row>
        {/* <Row>
          <div className="buttalign">

          <span className="buttonalignment"> 
          <Button onClick={this.previous}>Previous</Button>

          </span>
        
          <span>

          <Button
           direction="next"
           directionText="Next"
           onClick={this.next}>Next</Button>
          </span>
          </div>
        
        </Row> */}
        
      </React.Fragment>
    )
  }
}

export default VideoLink
