import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import user1 from "../../../assets/images/users/avatar-1.jpg";
import ChangePasswordModal from "../../../pages/Authentication/Changepassword"; 

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false); 
  const [logindate, setlogindate] = useState("");
  const [username, setusername] = useState("");
  const [user_Name, setUser_Name] = useState("");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  useEffect(() => {
   
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser && authUser.UserInfo.lastlogin) {
        setlogindate(authUser.UserInfo.lastlogin);
    }
    if (authUser && authUser.UserInfo.st_name) {
      setUser_Name(authUser.UserInfo.st_name);
  }
    
  }, []);

  useEffect(() => {
   
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser && authUser.UserInfo.st_name) {
     
      setusername(authUser.UserInfo.st_name.charAt(0).toUpperCase());
    }
  }, []);

  
  const toggleChangePasswordModal = () => {
    setShowChangePasswordModal(!showChangePasswordModal);
  };



  return (
    <React.Fragment>
      {logindate && <div style={{marginTop:'25px', fontSize:'10px'}}>Last Login: {logindate}</div>}
      {!logindate && <div style={{marginTop:'25px', fontSize:'15px'}}>Welcome {user_Name}</div>}
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
      <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
         
          {/* Display the first letter of the username as the profile image */}
          <div className="rounded-circle header-profile-user" style={{ width: '40px', height: '40px', background: '#007bff', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}>
            {username}
          </div>
          {/* <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span> */}
          {/* <i className="mdi mdi-chevron-down d-none d-xl-inline-block" /> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}
          </DropdownItem>
          <DropdownItem tag="a" href="#" onClick={toggleChangePasswordModal}> 
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Change Password")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      
      <ChangePasswordModal isOpen={showChangePasswordModal} toggle={toggleChangePasswordModal} />
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));