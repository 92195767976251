import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import { Link } from 'react-router-dom';
// import { Card, CardBody, Col,Container } from 'reactstrap';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import * as url from "../../../src/helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import withRouter from "components/Common/withRouter";
import Spinners from "../../components/Common/Spinner";
import "./common.scss"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { values } from "lodash";

const UserProfile = () => {
  //meta title
  document.title = "Profile | ICE - Student Portal";

  const dispatch = useDispatch();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [preferences, setpreferences] = useState("");
  const [categories, setCategories] = useState([]);
  const [mcategories, setmCategories] = useState([]);
  const [Topic, setTopic] = useState([]);
  const [mTopic, setmTopic] = useState([]);
  const [interested, setinterested] = useState("");
  const [stuname, setstuname] = useState("");
  const [stuphone, setstuphone] = useState("");
  const [stumail, setstumail] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [email, setemail] = useState("");
  const [phoneno, setphoneno] = useState("");
  const [name, setname] = useState("");
  const [preferencevalue, setpreferencevalue] = useState("");
  const [interestedvalue, setinterestedvalue] = useState("");
  const [idx, setidx] = useState(1);
  const [showEditForm, setShowEditForm] = useState(false);
  const user = getLoggedInUser()
  const [username, setusername] = useState("");
  const [isLoading, setLoading] = useState(false);


  useEffect(() => {

    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser && authUser.UserInfo.st_name) {

      setusername(authUser.UserInfo.st_name.charAt(0).toUpperCase());
    }
  }, []);
  const profileForm = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobileno: ""

    },
  })
  const ProfileProperties = createSelector(
    (state) => state.Profile,
    (profile) => ({
      error: profile.error,
      success: profile.success,
    })
  );

  const {
    error,
    success
  } = useSelector(ProfileProperties);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setphoneno(obj.phoneno)
        setidx(obj.uid);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username);
        setemail(obj.email);
        setphoneno(obj.phoneno)
        setidx(obj.uid);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  useEffect(() => {
    if (mcategories)
      fetchData();
  }, [categories]);

  useEffect(() => {
    masterData();
  }, []);

  const masterData = async () => {
    try {

      const response = await axios.post(url.BASE_URL + 'master/getexboard_exboardcategoryList');
      setCategories(response.data.body.exboardexcategorylist);
      setTopic(response.data.body.topiclist);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = (event) => {

  };


  const fetchData = async () => {
    setLoading(true)

    const data = {
      stid: Number(user.UserInfo.stid),
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'user/getStudentDetailsList', data, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await fetchData();
          }
        }
      }

      if (response?.data?.body?.stu_preferences) {
        setpreferences(response.data.body.stu_preferences);
        setpreferencevalue(response.data.body.stu_preferences[0]?.exctid || 0);
        setinterested(response.data.body.stu_interested);
        setinterestedvalue(response.data.body.stu_interested[0]?.mstid || 0);
        setstuname(response.data.body.stu_personal_details[0].st_name);
        profileForm.values.name = response.data.body.stu_personal_details[0].st_name
        profileForm.values.email = response.data.body.stu_personal_details[0].st_email
        profileForm.values.mobileno = response.data.body.stu_personal_details[0].st_mobileno

        setstuphone(response.data.body.stu_personal_details[0].st_mobileno);
        setstumail(response.data.body.stu_personal_details[0].st_email);
        setwhatsapp(response.data.body.stu_personal_details[0].st_whatsappenabled)

        if (categories && categories.length > 0) {
          const categoryList_arr = Object.assign([], categories)
          const result = []
          categoryList_arr.forEach(i => {
            const obj = { ...i }
            const st_categories = response.data.body.stu_preferences.filter((e) => Number(e.exctid) === Number(obj.exctid))
            if (st_categories[0]?.exctid === obj.exctid) {
              obj['checked'] = true
            }
            else {
              obj['checked'] = false
            }

            result.push(obj)

          })
          setmCategories(result)
        }


        if (Topic && Topic.length > 0) {
          const TopicList_arr = Object.assign([], Topic)
          const result = []
          TopicList_arr.forEach(i => {
            const obj = { ...i }
            const st_topic = response.data.body.stu_interested.filter((e) => Number(e.mstid) === Number(obj.mstid))
            if (st_topic[0]?.mstid === obj.mstid) {
              obj['checked'] = true
            }
            result.push(obj)
            setmTopic(result)
          })
        }



      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      console.error("student detailsAPI Error:", error);
    } finally {

    }
  }


  const alphOnly = (event) => {
    const regex = /^[a-zA-Z\s]*$/;
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (regex.test(key) || key === '') {
      return true
    }
    event.preventDefault()
    return false
  }


  const setData = async (values) => {
    setLoading(true)
    const st_whatsappenabled = document.getElementById('customSwitchsizesm').checked ? 1 : 0;

    const starr_categories = mcategories.filter((e) => e.checked === true)
    const starr_topic = mTopic.filter((e) => e.checked === true)

    const data = {
      user_id: Number(user.UserInfo.stid),
      process_type: "update",
      st_name: profileForm.values.name,
      st_email: profileForm.values.email,
      st_mobileno: profileForm.values.mobileno,
      st_whatsappenabled: st_whatsappenabled,
      stid: Number(user.UserInfo.stid),
      st_preferences: starr_categories,
      st_interested: starr_topic
    };

    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      if (starr_categories && starr_categories.length > 0 && starr_topic && starr_topic.length > 0 && profileForm.values.name) {
        const response = await axios.post(url.BASE_URL + 'user/updateUserProfile', data, config);
        if (response && response?.data?.body?.statusFlag === 3) {
          toast.error(response.data.body.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
        }

        if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();

          if (refresh) {
            if (Number(refresh) === constants.invalidRefreshToken) {
              logoutUser();
            } else {
              await setData();
            }
          }
        }

        if (response.data.body.statusFlag === 1) {
          const data = JSON.parse(localStorage.getItem('authUser'))
          var data1 = delete data?.UserInfo?.stu_interested
          data.UserInfo['stu_interested'] = starr_topic
          localStorage.setItem("authUser", JSON.stringify(data));



          toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
          setTimeout(() => {
            window.location.reload()
            setShowEditForm(false)

          }, 2000);

        } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
          // toast.error(response.data.body.message, { autoClose: 2000 });
        }
      } else {
        starr_categories && starr_categories.length == 0 ?
          toast.error('Please select atleast one preferences', { position: toast.POSITION.TOP_RIGHT })
          : starr_topic && starr_topic.length == 0 ?
            toast.error('Please select atleast one looking for', { position: toast.POSITION.TOP_RIGHT }) :
            profileForm && profileForm.values && profileForm.values.name == "" ?
              toast.error('Please enter name', { position: toast.POSITION.TOP_RIGHT }) :
              ""

      }

    } catch (error) {
      // Handle error
      console.error("student detailsAPI Error:", error);
    } finally {

    }
  }


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || '',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    }
  });
  const fnShowEditForm = () => {
    setButtonDisabled(true)
    setShowEditForm(true)
  }
  const fnUpdate = () => {
    setShowEditForm(false)
  }
  const fnHide = () => {
    setShowEditForm(false)
  }

  const checkhandlePerference = (row) => {
    if (categories && categories.length > 0) {
      const categoriesList_arr = Object.assign([], mcategories)
      const result = []
      categoriesList_arr.forEach(i => {
        const obj = { ...i }
        if (row.exctid === obj.exctid) {
          if (obj.checked === true) {
            obj['checked'] = false
          } else if (obj.checked === false) {
            obj['checked'] = true
          } else {
            obj['checked'] = true
          }
        }
        result.push(obj)
        setmCategories(result)
      })
    }
  };



  const checkhandleTopic = (row) => {
    if (Topic && Topic.length > 0) {
      const TopicList_arr = Object.assign([], mTopic)
      const result = []
      TopicList_arr.forEach(i => {
        const obj = { ...i }
        if (row.mstid === obj.mstid) {
          if (obj.checked == true) {


            obj['checked'] = false
          }
          // else if (obj.checked === false) {
          //   obj['checked'] = true
          // }
          else {
            //  delete obj.age;
            obj['checked'] = true
          }
        }
        result.push(obj)
        setmTopic(result)
      })
    }
  };

  return (
    <React.Fragment>
      <div className="page-content bg-radiant" >
        <Container fluid className="row" style={{ justifyContent: 'center' }}>
          {/* Render Breadcrumb */}


          <Card className="col-lg-4">
            <CardBody>
              <div className="text-center">
                <div className="rounded-circle header-profile-user" style={{ width: '40px', height: '40px', background: '#007bff', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', marginLeft: "35%" }}>
                  {username}
                </div>

              </div>
              <h5 className="mt-3 mb-1 marginleftname" >{stuname}</h5>
              <p className="text-muted mb-0 marginleftname" >{stumail}</p>
              <ul className="list-unstyled mt-4">
                <li>
                  <div className="d-flex">
                    <i className="bx bx-phone text-primary fs-4"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">Phone</h6>
                      <p className="text-muted fs-14 mb-0">{stuphone}</p>
                    </div>
                  </div>
                </li>

                <li className="mt-3">
                  <div className="d-flex">
                    <i className="bx bx-package text-primary fs-4"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">Preferences</h6>
                      <p className="text-muted fs-14 text-break mb-0">
                        {preferences.length > 0 ? (
                          preferences.map((preference, index) => (
                            <span key={index} className="badge badge-soft-primary fs-6 mx-1">
                              {preference.exboard_excategory}
                            </span>
                          ))
                        ) : (
                          <span>No preferences available</span>
                        )}
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-3">
                  <div className="d-flex">
                    <i className="bx bx-star text-primary fs-4"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">Looking For</h6>
                      <p className="text-muted fs-14 text-break mb-0">
                        {interested.length > 0 ? (
                          interested.map((interest, index) => (
                            <span key={index} className="badge badge-soft-primary fs-6 mx-1">
                              {interest.mst_displaytext}
                            </span>
                          ))
                        ) : (
                          <span>No interested available</span>
                        )}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="mt-4">
                <Button to="#" onClick={fnShowEditForm} disabled={isButtonDisabled} className="btn btn-soft-primary btn-hover w-100 rounded"><i className="mdi mdi-edit"></i> Edit Profile</Button>
              </div>
            </CardBody>
          </Card>


          {showEditForm == true ?
            <Card className="col-lg-4">
              <CardBody>
                <h6 className="mb-4 card-title">Edit Profile</h6>
                <Form className="outer-repeater" onSubmit={profileForm.handleSubmit}>
                  <div data-repeater-list="outer-group" className="outer">
                    <div data-repeater-item className="outer">
                      <div className="mb-3">
                        <Label htmlFor="formName">Name <span style={{ color: 'red' }}>*</span>: </Label>
                        <Input
                          type="text"
                          name="name"
                          id="formName"
                          placeholder="Enter your Name..."
                          className="form-control"
                          value={profileForm.values.name}
                          onKeyPress={event => alphOnly(event)}
                          maxLength={30}
                          onChange={profileForm.handleChange}
                          onBlur={profileForm.handleBlur}
                          invalid={profileForm.touched.name && profileForm.errors.name}
                        />
                        {profileForm.errors.name && profileForm.touched.name && (
                          <FormFeedback type="invalid">{profileForm.errors.name}</FormFeedback>
                        )}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="formEmail">Email<span style={{ color: 'red' }}>*</span> :</Label>
                        <Input
                          style={{ background: '#ededed' }}
                          readOnly={true}
                          type="email"
                          id="formEmail"
                          name="email"
                          placeholder="Enter your Email..."
                          className="form-control"
                          value={profileForm.values.email}
                          // value={stumail}
                          onChange={profileForm.handleChange}
                          onBlur={profileForm.handleBlur}
                          invalid={
                            profileForm.touched.email && profileForm.errors.email ? true : false
                          }
                        />
                        {
                          profileForm.errors.email && profileForm.touched.email ? (
                            <FormFeedback type="invalid">{profileForm.errors.email}</FormFeedback>
                          ) : null
                        }
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Mobile No.<span style={{ color: 'red' }}>*</span>
                          <span className="whatsapp">

                            <i className="mdi mdi-whatsapp text-success display-4 whatsappicon1" />
                          </span>

                          <span>

                            <p
                              className="form-check form-switch"

                            >
                              <input
                                type="checkbox"
                                className="form-check-input togglealign1"
                                id="customSwitchsizesm"
                                defaultChecked={whatsapp}

                              />

                            </p>
                          </span>

                        </Label>
                        <Input
                          style={{ background: '#ededed' }}

                          type="text"
                          id="text"
                          name="text"
                          placeholder="Enter your phone no..."
                          className="form-control"
                          value={profileForm.values.mobileno}
                          onChange={profileForm.handleChange}
                          onBlur={profileForm.handleBlur}
                          invalid={
                            profileForm.touched.mobileno && profileForm.errors.mobileno ? true : false
                          }
                        />
                        {
                          profileForm.errors.mobileno && profileForm.touched.mobileno ? (
                            <FormFeedback type="invalid">{profileForm.errors.mobileno}</FormFeedback>
                          ) : null
                        }
                      </div>


                      <div className="mb-3">
                        <Label className="d-block mb-3">Preferences<span style={{ color: 'red' }}>*</span> :</Label>
                        <div>
                          <Form>
                            {mcategories.map(category => (
                              <Row key={category.exctid}>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label check className="pref">
                                      <Input
                                        type="checkbox"
                                        name={`category-${category.exctid}`}
                                        value={category.exctid}
                                        checked={category.checked}
                                        onClick={(e) => checkhandlePerference(category)}
                                      />{' '}
                                      {category.exboard_exboardcategory}
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                            ))}
                          </Form>
                        </div>
                        &nbsp;
                      </div>

                      <div className="mb-3">
                        <Label className="d-block mb-3">Looking For<span style={{ color: 'red' }}>*</span> :</Label>
                        <div>
                          <Form>
                            {mTopic.map(Toplist => (
                              <Row key={Toplist.mstid}>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        name={`Toplist-${Toplist.mstid}`}
                                        checked={Toplist.checked}
                                        // onChange={handleChange}
                                        onClick={(e) => checkhandleTopic(Toplist)}
                                      />{' '}
                                      {Toplist.mst_displaytext}
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                            ))}



                          </Form>
                        </div>
                        &nbsp;
                      </div>
                      <Button
                        type="submit"
                        color="primary"
                        style={{ marginLeft: "85%" }}
                        onClick={() => setData()}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card> : null}
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
};

// const UserProfile = () => {
//   //meta title
//   document.title = "Profile | ICE - Student Portal";
//   const [isLoading, setLoading] = useState(false);

//   const dispatch = useDispatch();
//   const [preferences, setpreferences] = useState("");
//   const [categories, setCategories] = useState([]);
//   const [Topic, setTopic] = useState([]);
//   const [interested, setinterested] = useState("");
//   const [stuname, setstuname] = useState("");
//   const [stuphone, setstuphone] = useState("");
//   const [stumail, setstumail] = useState("");
//   const [whatsapp, setwhatsapp] = useState("");
//   const [email, setemail] = useState("");
//   const [phoneno, setphoneno] = useState("");
//   const [name, setname] = useState("");
//   const [preferencevalue, setpreferencevalue] = useState("");
//   const [interestedvalue, setinterestedvalue] = useState("");
//   const [idx, setidx] = useState(1);
//   const [showEditForm, setShowEditForm] = useState(false);
//   const user = getLoggedInUser()
//   const [username, setusername] = useState("");


//   useEffect(() => {

//     const authUser = JSON.parse(localStorage.getItem("authUser"));
//     if (authUser && authUser.UserInfo.st_name) {

//       setusername(authUser.UserInfo.st_name.charAt(0).toUpperCase());
//     }
//   }, []);
//   const profileForm = useFormik({
//     initialValues: {
//       name: "",
//       email: "",
//       mobileno: ""

//     },
//   })
//   const ProfileProperties = createSelector(
//     (state) => state.Profile,
//     (profile) => ({
//       error: profile.error,
//       success: profile.success,
//     })
//   );

//   const {
//     error,
//     success
//   } = useSelector(ProfileProperties);

//   useEffect(() => {
//     if (localStorage.getItem("authUser")) {
//       const obj = JSON.parse(localStorage.getItem("authUser"));
//       if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//         setname(obj.displayName);
//         setemail(obj.email);
//         setphoneno(obj.phoneno)
//         setidx(obj.uid);
//       } else if (
//         process.env.REACT_APP_DEFAULTAUTH === "fake" ||
//         process.env.REACT_APP_DEFAULTAUTH === "jwt"
//       ) {
//         setname(obj.username);
//         setemail(obj.email);
//         setphoneno(obj.phoneno)
//         setidx(obj.uid);
//       }
//       setTimeout(() => {
//         dispatch(resetProfileFlag());
//       }, 3000);
//     }
//   }, [dispatch, success]);

//   useEffect(() => {
//     if (Topic && Topic.length == 0 && categories && categories.length == 0) {

//       fetchData();
//     }
//   }, [categories, Topic]);

//   useEffect(() => {
//     masterData();
//   }, []);

//   const masterData = async () => {
//     try {

//       const response = await axios.post(url.BASE_URL + 'master/getexboard_exboardcategoryList');
//       setCategories(response.data.body.exboardexcategorylist);
//       setTopic(response.data.body.topiclist);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const handleChange = (event) => {

//   };


//   const fetchData = async () => {
//     setLoading(true)

//     const data = {
//       stid: Number(user.UserInfo.stid),
//     }
//     const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

//     try {
//       const response = await axios.post(url.BASE_URL + 'user/getStudentDetailsList', data, config);
//       if (response && response?.data?.body?.statusFlag === 3) {
//         toast.error(response.data.body.message, { autoClose: 2000 });
//         localStorage.removeItem("authUser");
//         window.location.reload();
//         return;
//       }

//       if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
//         const refresh = await getRefreshToken();

//         if (refresh) {
//           if (Number(refresh) === constants.invalidRefreshToken) {
//             logoutUser();
//           } else {
//             await fetchData();
//           }
//         }
//       }

//       if (response?.data?.body?.stu_preferences) {
//         console.log('res', response?.data?.body)
//         setpreferences(response.data.body.stu_preferences);
//         setpreferencevalue(response.data.body.stu_preferences[0].exctid);
//         setinterested(response.data.body.stu_interested);
//         setinterestedvalue(response.data.body.stu_interested[0].mstid);
//         setstuname(response.data.body.stu_personal_details[0].st_name);
//         profileForm.values.name = response.data.body.stu_personal_details[0].st_name
//         profileForm.values.email = response.data.body.stu_personal_details[0].st_email
//         profileForm.values.mobileno = response.data.body.stu_personal_details[0].st_mobileno

//         setstuphone(response.data.body.stu_personal_details[0].st_mobileno);
//         setstumail(response.data.body.stu_personal_details[0].st_email);
//         setwhatsapp(response.data.body.stu_personal_details[0].st_whatsappenabled)

//         if (categories && categories.length > 0) {
//           const categoryList_arr = Object.assign([], categories)
//           const result = []
//           categoryList_arr.forEach(i => {
//             const obj = { ...i }
//             const st_categories = response.data.body.stu_preferences.filter((e) => Number(e.exctid) === Number(obj.exctid))
//             if (st_categories[0]?.exctid === obj.exctid) {
//               obj['checked'] = true
//             }

//             result.push(obj)
//             setCategories(result)
//           })
//         }


//         if (Topic && Topic.length > 0) {
//           const TopicList_arr = Object.assign([], Topic)
//           const result = []
//           TopicList_arr.forEach(i => {
//             const obj = { ...i }
//             const st_topic = response.data.body.stu_interested.filter((e) => Number(e.mstid) === Number(obj.mstid))
//             if (st_topic[0]?.mstid === obj.mstid) {
//               obj['checked'] = true
//             }
//             result.push(obj)
//             setTopic(result)
//           })
//         }



//       } else {
//         toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
//       }
//     } catch (error) {
//       // Handle error
//       console.error("student detailsAPI Error:", error);
//     } finally {

//     }
//   }



//   const setData = async (values) => {
//     setLoading(true)

//     const st_whatsappenabled = document.getElementById('customSwitchsizesm').checked ? 1 : 0;
//     const starr_categories = categories.filter((e) => e.checked === true)
//     const starr_topic = Topic.filter((e) => e.checked === true)

//     const data = {
//       user_id: Number(user.UserInfo.stid),
//       process_type: "update",
//       st_name: profileForm.values.name,
//       st_email: profileForm.values.email,
//       st_mobileno: profileForm.values.mobileno,
//       st_whatsappenabled: st_whatsappenabled,
//       stid: Number(user.UserInfo.stid),
//       st_preferences: starr_categories,
//       st_interested: starr_topic
//     };

//     const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

//     try {
//       const response = await axios.post(url.BASE_URL + 'user/updateUserProfile', data, config);
//       if (response && response?.data?.body?.statusFlag === 3) {
//         toast.error(response.data.body.message, { autoClose: 2000 });
//         localStorage.removeItem("authUser");
//         window.location.reload();
//         return;
//       }

//       if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
//         const refresh = await getRefreshToken();

//         if (refresh) {
//           if (Number(refresh) === constants.invalidRefreshToken) {
//             logoutUser();
//           } else {
//             await setData();
//           }
//         }
//       }

//       if (response.data.body.statusFlag === 1) {
//         toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
//       } else {
//         toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
//         // toast.error(response.data.body.message, { autoClose: 2000 });
//       }
//     } catch (error) {
//       // Handle error
//       console.error("student detailsAPI Error:", error);
//     } finally {

//     }
//   }


//   const validation = useFormik({
//     // enableReinitialize : use this flag when initial values needs to be changed
//     enableReinitialize: true,

//     initialValues: {
//       username: name || '',
//       idx: idx || '',
//     },
//     validationSchema: Yup.object({
//       username: Yup.string().required("Please Enter Your UserName"),
//     }),
//     onSubmit: (values) => {
//       console.log('value', values)
//       dispatch(editProfile(values));
//     }
//   });
//   const fnShowEditForm = () => {
//     setShowEditForm(true)
//   }
//   const fnUpdate = () => {
//     setShowEditForm(false)
//   }
//   const fnHide = () => {
//     setShowEditForm(false)
//   }

//   const checkhandlePerference = (row) => {
//     if (categories && categories.length > 0) {
//       const categoriesList_arr = Object.assign([], categories)
//       const result = []
//       categoriesList_arr.forEach(i => {
//         const obj = { ...i }
//         if (row.exctid === obj.exctid) {
//           if (obj.checked === true) {
//             obj['checked'] = false
//           } else if (obj.checked === false) {
//             obj['checked'] = true
//           } else {
//             obj['checked'] = obj['checked']
//           }
//         }
//         result.push(obj)
//         setCategories(result)
//       })
//     }
//   };



//   const checkhandleTopic = (row) => {
//     if (Topic && Topic.length > 0) {
//       const TopicList_arr = Object.assign([], Topic)
//       const result = []
//       TopicList_arr.forEach(i => {
//         const obj = { ...i }
//         if (row.mstid === obj.mstid) {
//           if (obj.checked === true) {
//             obj['checked'] = false
//           } else if (obj.checked === false) {
//             obj['checked'] = true
//           } else {
//             obj['checked'] = obj['checked']
//           }
//         }
//         result.push(obj)
//         setTopic(result)
//       })
//     }
//   };

//   return (
//     <React.Fragment>
//       <div className="page-content bg-radiant" >
//         <Container fluid className="row" style={{ justifyContent: 'center' }}>
//           {/* Render Breadcrumb */}

//           {isLoading ?
//               <Spinners setLoading={setLoading} /> : 
//           <Card className="col-lg-4">
//             <CardBody>
//               <div className="text-center">
//                 <div className="rounded-circle header-profile-user" style={{ width: '40px', height: '40px', background: '#007bff', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', marginLeft: "35%" }}>
//                   {username}
//                 </div>

//               </div>
//               <h5 className="mt-3 mb-1 marginleftname" >{stuname}</h5>
//               <p className="text-muted mb-0 marginleftname" >{stumail}</p>
//               <ul className="list-unstyled mt-4">
//                 <li>
//                   <div className="d-flex">
//                     <i className="bx bx-phone text-primary fs-4"></i>
//                     <div className="ms-3">
//                       <h6 className="fs-14 mb-2">Phone</h6>
//                       <p className="text-muted fs-14 mb-0">{stuphone}</p>
//                     </div>
//                   </div>
//                 </li>

//                 <li className="mt-3">
//                   <div className="d-flex">
//                     <i className="bx bx-package text-primary fs-4"></i>
//                     <div className="ms-3">
//                       <h6 className="fs-14 mb-2">Preferences</h6>
//                       <p className="text-muted fs-14 text-break mb-0">
//                         {preferences.length > 0 ? (
//                           preferences.map((preference, index) => (
//                             <span key={index} className="badge badge-soft-primary fs-6 mx-1">
//                               {preference.exboard_excategory}
//                             </span>
//                           ))
//                         ) : (
//                           <span>No preferences available</span>
//                         )}
//                       </p>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="mt-3">
//                   <div className="d-flex">
//                     <i className="bx bx-star text-primary fs-4"></i>
//                     <div className="ms-3">
//                       <h6 className="fs-14 mb-2">Looking For</h6>
//                       <p className="text-muted fs-14 text-break mb-0">
//                         {interested.length > 0 ? (
//                           interested.map((interest, index) => (
//                             <span key={index} className="badge badge-soft-primary fs-6 mx-1">
//                               {interest.mst_displaytext}
//                             </span>
//                           ))
//                         ) : (
//                           <span>No interested available</span>
//                         )}
//                       </p>
//                     </div>
//                   </div>
//                 </li>
//               </ul>
//               <div className="mt-4">
//                 <Link to="#" onClick={fnShowEditForm} className="btn btn-soft-primary btn-hover w-100 rounded"><i className="mdi mdi-edit"></i> Edit Profile</Link>
//               </div>
//             </CardBody>
//           </Card>
// }

//           {showEditForm == true ?
//             <Card className="col-lg-4">
//               <CardBody>
//                 <h6 className="mb-4 card-title">Edit Profile</h6>
//                 <Form className="outer-repeater" onSubmit={profileForm.handleSubmit}>
//                   <div data-repeater-list="outer-group" className="outer">
//                     <div data-repeater-item className="outer">
//                       <div className="mb-3">
//                         <Label htmlFor="formName">Name : </Label>
//                         <Input
//                           type="text"
//                           name="name"
//                           id="formName"
//                           placeholder="Enter your Name..."
//                           className="form-control"
//                           value={profileForm.values.name}

//                           onChange={profileForm.handleChange}
//                           onBlur={profileForm.handleBlur}
//                           invalid={profileForm.touched.name && profileForm.errors.name}
//                         />
//                         {profileForm.errors.name && profileForm.touched.name && (
//                           <FormFeedback type="invalid">{profileForm.errors.name}</FormFeedback>
//                         )}
//                       </div>

//                       <div className="mb-3">
//                         <Label htmlFor="formEmail">Email :</Label>
//                         <Input
//                           type="email"
//                           id="formEmail"
//                           name="email"
//                           placeholder="Enter your Email..."
//                           className="form-control"
//                           value={profileForm.values.email}
//                           // value={stumail}
//                           onChange={profileForm.handleChange}
//                           onBlur={profileForm.handleBlur}
//                           invalid={
//                             profileForm.touched.email && profileForm.errors.email ? true : false
//                           }
//                         />
//                         {
//                           profileForm.errors.email && profileForm.touched.email ? (
//                             <FormFeedback type="invalid">{profileForm.errors.email}</FormFeedback>
//                           ) : null
//                         }
//                       </div>

//                       <div className="mb-3">
//                         <Label className="form-label">Mobile No.

//                           <i className="mdi mdi-whatsapp text-success display-4 whatsappicon" />

//                           <p
//                             className="form-check form-switch"

//                           >
//                             <input
//                               type="checkbox"
//                               className="form-check-input togglealign"
//                               id="customSwitchsizesm"
//                               defaultChecked={whatsapp}

//                             />

//                           </p>
//                         </Label>
//                         <Input
//                           type="text"
//                           id="text"
//                           name="text"
//                           placeholder="Enter your phone no..."
//                           className="form-control"
//                           value={profileForm.values.mobileno}
//                           onChange={profileForm.handleChange}
//                           onBlur={profileForm.handleBlur}
//                           invalid={
//                             profileForm.touched.mobileno && profileForm.errors.mobileno ? true : false
//                           }
//                         />
//                         {
//                           profileForm.errors.mobileno && profileForm.touched.mobileno ? (
//                             <FormFeedback type="invalid">{profileForm.errors.mobileno}</FormFeedback>
//                           ) : null
//                         }
//                       </div>


//                       <div className="mb-3">
//                         <Label className="d-block mb-3">Preferences :</Label>
//                         <div>
//                           <Form>
//                             {categories.map(category => (
//                               <Row key={category.exctid}>
//                                 <Col lg="6">
//                                   <div className="mb-3">
//                                     <Label check>
//                                       <Input
//                                         type="checkbox"
//                                         name={`category-${category.exctid}`}
//                                         value={category.exctid}
//                                         checked={category.checked}
//                                         onChange={(e) => checkhandlePerference(category)}
//                                       />{' '}
//                                       {category.exboard_exboardcategory}
//                                     </Label>
//                                   </div>
//                                 </Col>
//                               </Row>
//                             ))}
//                           </Form>
//                         </div>
//                         &nbsp;
//                       </div>

//                       <div className="mb-3">
//                         <Label className="d-block mb-3">Looking For :</Label>
//                         <div>
//                           <Form>
//                             {Topic.map(Toplist => (
//                               <Row key={Toplist.mstid}>
//                                 <Col lg="6">
//                                   <div className="mb-3">
//                                     <Label check>
//                                       <Input
//                                         type="checkbox"
//                                         name={`Toplist-${Toplist.mstid}`}
//                                         checked={Toplist.checked}
//                                         // onChange={handleChange}
//                                         onChange={(e) => checkhandleTopic(Toplist)}
//                                       />{' '}
//                                       {Toplist.mst_displaytext}
//                                     </Label>
//                                   </div>
//                                 </Col>
//                               </Row>
//                             ))}



//                           </Form>
//                         </div>
//                         &nbsp;
//                       </div>
//                       <Button
//                         type="submit"
//                         color="primary"
//                         style={{ marginLeft: "85%" }}
//                         onClick={() => setData()}
//                       >
//                         Submit
//                       </Button>
//                     </div>
//                   </div>
//                 </Form>
//               </CardBody>
//             </Card> : null}
//         </Container>
//       </div>
//       <ToastContainer
//         position="top-right"
//         autoClose={2000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="light"
//       />
//     </React.Fragment>
//   );
// };

export default withRouter(UserProfile);