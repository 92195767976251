import React, { useEffect, useState } from "react"
import { useParams, Link, useNavigate } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
// action
import { registerUser, apiError } from "../../store/actions";
import * as url from "../../../src/helpers/url_helper";

const ConfirmMail = () => {

  //meta title
  document.title = "Confirm Mail | Skote - React Admin & Dashboard Template";
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  const { emailid } = useParams()
  useEffect(() => {
    verfyemail()
  }, [emailid])
  const navigate = useNavigate();



  const [seconds, setSeconds] = useState(10);


  let interval;

  useEffect(() => {

      if (seconds == 0) {
          navigate('/login')
      } else {

          startClock();

          return () => clearInterval(interval);
      }


  }, [seconds]);

  const startClock = () => {
      interval = setInterval(() => {

          if (Number(seconds) === 0) {

              setSeconds(10);

          } else {
              setSeconds((prevSeconds) => Number(prevSeconds) - 1);
          }


      }, 1000);
  };


  const verfyemail = async () => {
    try {

      var data = {
        emailid: emailid
      }
      const response = await axios.post(url.BASE_URL + 'user/verificationEmail', data);
      if (response.data.body.statusFlag == 2) {
        toast.error(response?.data?.body?.messages, { position: toast.POSITION.TOP_RIGHT });
        // toast.error(response?.data?.body?.messages)
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">

        <Container>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Verified Successfully !!!</h4>
                        <div>
                          <p id="timer"></p>
                          <p>This will redirect you to Sign in screen {seconds} seconds.</p>
                        </div>
                        <div>
                          <a href="/login">Click here to Sign in.</a>
                        </div>
                        {/* <p className="text-muted">
                          At vero eos et accusamus et iusto odio dignissimos
                          ducimus qui blanditiis praesentium voluptatum
                          deleniti atque corrupti quos dolores et
                        </p> */}
                        {/* <div className="mt-4">
                          <Link to="/login" className="btn btn-success">
                            Ok
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="mb-0 mt-1">
                  All rights reserved.© {new Date().getFullYear()}. Powered
                  by
                  <a onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                </p>
              </div>
            </Col>
          </Row>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfirmMail
