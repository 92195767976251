import React, { useState, useEffect } from "react"
import { useLocation, Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

import { useSelector, useDispatch } from "react-redux";

const EmailVerification = () => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  //meta title
  document.title = "Email Verification | Skote - React Admin & Dashboard Template";
  const dispatch = useDispatch();
  const [emailId, setEmailId] = useState("example@abc.com")
  const data = useLocation();
  useEffect(() => {
    if (data?.state) {
      if (data?.state?.email_id) {
        setEmailId(data?.state?.email_id)
      }

    }

  }, [data])
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Check your email</h4>
                        <p>
                          We have sent you verification email{" "}
                          <span className="fw-semibold">
                            {emailId}
                          </span>
                          , Please check it
                        </p>
                        <Link
                    to="/login"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    Sign In here
                  </Link>{" "}
                      
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Didn&apos;t receive an email ?{" "}
                  <a href="#" className="fw-medium text-primary">
                    {" "}
                    Resend{" "}
                  </a>{" "}
                </p> */}
                {/* <div className="text-sm-end d-none d-sm-block"> */}
                  <p className="mb-0 mt-1">
                    All rights reserved.© {new Date().getFullYear()}. Powered
                        by
                        <a onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                  </p>
                {/* </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default EmailVerification
