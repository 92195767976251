

export const examTypes = {
    SUBJECTWISE: 0,
    TOPICWISE: 1,
    MODELEXAM: 2,

}

export const REGISTRATIONVARIABLE = {
    PREFERENCEVALIDATE : "Please select alteast one preference",
    LOOKINGFORVALIDATE : "Please select alteast one looking for"
}

export const ONLINE = {
    EXAMLEVELVALIDATE : "Please select exam level"
}

export const REVIEWTYPE = [
    {
        label: "Full Test",
        value: 1
    },
    {
        label: "Wrong Answered",
        value: 2
    }
]
   