import React, { useEffect, useState } from "react"
import axios from "axios";
import { Row, Col, Card, Button } from "reactstrap"
import profileImg from "../../assets/images/profile-img.png"
import './dashboard.scss'
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import * as url from "../../../src/helpers/url_helper";
import VideoLink from "./videocarousel";
import './dashboard.scss';

const WelcomeComp = () => {

  const [isDisplayed, setIsDisplayed] = useState(false);
  const [username, setusername] = useState("");
  const [preferences, setpreferences] = useState("");
  const [interested, setinterested] = useState("");
  const [stuname, setstuname] = useState("");
  const [stuphone, setstuphone] = useState("");
  const [stumail, setstumail] = useState("");
  const [Videolink, setVideoLink] = useState([])


  useEffect(() => {

    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser && authUser.UserInfo.st_name) {

      setusername(authUser.UserInfo.st_name.charAt(0).toUpperCase());
    }
  }, []);

  useEffect(() => {
    fetchData();
    fetchLink()
  }, []);
  useEffect(() => {
    if (Videolink && Videolink.length > 0) {
      setIsDisplayed(true);

    }
  }, [Videolink]);

  const fetchData = async () => {
    const user = getLoggedInUser()

    const data = {
      stid: Number(user.UserInfo.stid),
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'user/getStudentDetailsList', data, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await fetchData();
          }
        }
      }

      if (response?.data?.body?.stu_preferences) {
        setpreferences(response.data.body.stu_preferences);
        setinterested(response.data.body.stu_interested);
        setstuname(response.data.body.stu_personal_details[0].st_name);
        setstuphone(response.data.body.stu_personal_details[0].st_mobileno);
        setstumail(response.data.body.stu_personal_details[0].st_email);
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      console.error("student detailsAPI Error:", error);
    } finally {

    }
  }
  const fetchLink = async () => {
    const user = getLoggedInUser()

    const data = {
      stid: Number(user.UserInfo.stid),
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'master/getVideolist', data, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await fetchLink();
          }
        }
      }

      if (response?.data?.body?.videoList) {
        var filtered_videos = response?.data?.body?.videoList.filter(e => e.gsv_stsid == 1)
        console.log(filtered_videos,'before filtered_videos')
        filtered_videos =   filtered_videos.map(e=>
          {
            e['gsv_link'] =  getVideoId(e?.gsv_link)
            return e
          }
          )

        console.log(filtered_videos,'filtered_videos')
        setVideoLink(filtered_videos)
      } else {
        // toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      // console.error("student detailsAPI Error:", error);
    } finally {

    }
  }

  function getVideoId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
}
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="landing-card">
          <Row>
            <Col xs="12" sm="12" md="5" style={{ background: '#f0f8ff' }}>
              <div className="text-center" style={{ marginTop: '15px' }}>


                <div className="rounded-circle header-profile-user" style={{ width: '40px', height: '40px', background: '#007bff', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', marginLeft: "35%" }}>
                  {username}
                </div>
              </div>
              <h5 className="mt-3 mb-1 marginleftname">{stuname}</h5>
              <p className="text-muted mb-0 marginleftname">{stumail}</p>
              <div className="preferenceoverflow">
              <ul className="list-unstyled mt-4 ml-md-0" >
                <li style={{ marginLeft: "30%" }}>
                  <div className="d-flex">
                    <i className="bx bx-phone text-primary fs-4"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">Phone</h6>
                      <p className="text-muted fs-14 mb-0">{stuphone}</p>
                    </div>
                  </div>
                </li>
                <li className="mt-3" style={{ marginLeft: "30%" }}>
                  <div className="d-flex" style={{ marginTop: '30px' }}>
                    <i className="bx bx-package text-primary fs-4"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">Preferences</h6>
                      <p className="text-muted fs-14 text-break mb-0">
                        {preferences.length > 0 ? (
                          preferences.map((preference, index) => (
                            <span key={index} className="badge badge-soft-primary fs-6 mx-1">
                              {preference.exboard_excategory}
                            </span>
                          ))
                        ) : (
                          <span>No preferences available</span>
                        )}
                      </p>
                    </div>
                  </div>
                </li>
                <li style={{ marginLeft: "30%" }}>
                  <div className="d-flex" style={{ marginTop: '30px' }}>
                    <i className="bx bx-star text-primary fs-4"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">Looking For</h6>
                      <p className="text-muted fs-14 text-break mb-0">
                        {interested.length > 0 ? (
                          interested.map((interest, index) => (
                            <span key={index} className="badge badge-soft-primary fs-6 mx-1">
                              {interest.mst_displaytext}
                            </span>
                          ))
                        ) : (
                          <span>No interested available</span>
                        )}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              </div>
            </Col>
            <Col xs="12"sm="12" md="7">
              <Card style={{height:'100%'}}>

              <div className="App">
                {isDisplayed &&
                  Videolink && Videolink.length > 0 ?
                  <VideoLink Videolink={Videolink} />                  
                  
                  : <img src={profileImg} alt="" className="img-fluid" />}
             
              </div>
              </Card>

            </Col>

          </Row>
        </div>

      </Card>
    </React.Fragment>
  )


}
export default WelcomeComp
