import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form, Button } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/vision.jpg";
import logo from "../../assets/images/logo.svg";
import logolight from "../../assets/images/trios-logo.jpg";
import axios from "axios";
import * as url from "../../../src/helpers/url_helper";

const ForgetPasswordPage = props => {

  //meta title
  document.title = "Forget Password | ICE - Student Portal";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailNotValid, setEmailNotValid] = useState("")

  const [isLoading, setIsLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email")
      .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,"Invalid email.")
    }),
    onSubmit: (values) => {
      // dispatch(userForgetPassword(values, props.history));
    }
  });


  const ForgotPasswordProperties = createSelector(
    (state) => state.ForgetPassword,
    (forgetPassword) => ({
      forgetError: forgetPassword.forgetError,
      forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
    })
  );

  const {
    forgetError,
    forgetSuccessMsg
  } = useSelector(ForgotPasswordProperties);

  const onClickResetBtn = async () => {
    validation.handleSubmit();
    if (validation.values.email === "" || validation.values.email === undefined || validation.values.email === null || !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(validation.values.email))) {
      return false;
    }
    setIsLoading(true)
    try {
      const data = {
        emailid: validation.values.email
      }
      const response = await axios.post(url.BASE_URL + 'user/forgotPasswordEmailVerification', data);
      if (response.data.body.statusFlag === 2) {
        setEmailNotValid(response.data.body.message)
        setIsLoading(false)
        return
      } else {
        navigate('/forgot-verify-email', {state:{email_id: validation.values.email}})
      }

    } catch (error) {
      setIsLoading(false)
      console.error('Error emaild check:', error);
    }
  }
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle" style={{ backgroundImage: `url(${profile})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <Col xs={7}>
                          <div className="text-primary p-4">
                            <img src={logolight} alt="Trios Logo" style={{ height: "45px", width: "85px", marginLeft: '400%' }} />
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">

                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                         <h4>
              Forgot password
            </h4>
            <hr></hr>        
                      <div className="mb-3">
                        <Label className="form-label">Enter Your Registered email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          // onChange={onChangeEmail}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}                          
                          invalid={
                            (validation.touched.email && validation.errors.email) || emailNotValid ? true : false
                          }
                        />
                           {emailNotValid && emailNotValid != "" ? (
                                              <FormFeedback type="invalid">{emailNotValid}</FormFeedback>
                                            ) : null}
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">


                          {isLoading ? <Button type="button" color="primary" disabled>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                            Validating
                                      </Button> : <Button type="button" color="primary"
                              // type="submit"
                              onClick={() => {onClickResetBtn()}}

                            >
                              Reset
                          </Button>}
                        </Col>
                      </Row>
                      <div className="mt-3 text-center">
                        <p>
                          Go back to{" "}
                          <Link to="/login" className="font-weight-medium text-primary">
                            Sign in
                  </Link>{" "}
                        </p>

                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
