import React, { useState, useEffect } from "react"
import { useLocation, Link, useNavigate } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap"

import { useSelector, useDispatch } from "react-redux";


const RedirectScreen = () => {
    // var count = 100;
    const navigate = useNavigate();
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
      };


    const [seconds, setSeconds] = useState(10);


    let interval;

    useEffect(() => {

        if (seconds == 0) {
            navigate('/login')
        } else {

            startClock();

            return () => clearInterval(interval);
        }


    }, [seconds]);

    const startClock = () => {
        interval = setInterval(() => {

            if (Number(seconds) === 0) {

                setSeconds(10);

            } else {
                setSeconds((prevSeconds) => Number(prevSeconds) - 1);
            }


        }, 1000);
    };

    return (

        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card>
                                <CardBody>
                                    <div className="p-2">
                                        <div className="text-center">
                                            <div className="avatar-md mx-auto">
                                                <div className="avatar-title rounded-circle bg-light">
                                                    <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                                                </div>
                                            </div>
                                            <div className="p-2 mt-4">
                                                <h4>Password Changed</h4>
                                                <p>
                                                    Your Password has changed Successfully!!!
                                                    {/* We have sent you verification email{" "} */}

                                                </p>

                                            </div>
                                            <div>
                                                <p id="timer"></p>
                                                <p>This will redirect you to login screen {seconds} seconds</p>
                                            </div>
                                            <div>
                                                <a href="/login">Click here to login</a>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                {/* <p>
                  Didn&apos;t receive an email ?{" "}
                  <a href="#" className="fw-medium text-primary">
                    {" "}
                    Resend{" "}
                  </a>{" "}
                </p> */}
                                {/* <div className="text-sm-end d-none d-sm-block"> */}
                                <p className="mb-0 mt-1">
                                    All rights reserved.© {new Date().getFullYear()}. Powered
                                    by
                                    <a onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                                </p>
                                {/* </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default RedirectScreen;