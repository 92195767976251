import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Spinners from "../../components/Common/Spinner";
import { useNavigate } from 'react-router-dom'
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import './test.css'
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Col, Row, Label, Card, CardBody, Badge, Button } from "reactstrap";
import axios from "axios";
import * as url from "../../../src/helpers/url_helper";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
//i18n
import { withTranslation } from "react-i18next";
import { REVIEWTYPE } from '../../constants/commonVariable'
const OnlineTestReview = (location) => {
  const data = useLocation();
  const store = useSelector(state => state.Blueprint)
  const [isLoading, setLoading] = useState(true);
  const [activeQuestion, setActiveQuestion] = useState(0)

  const [showResult, setShowResult] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [backflag, SetBlackFlag] = useState("0")

  const [answeredCount, setAnsweredCount] = useState(0)
  const [markedCount, setMarkedCount] = useState(0)
  const [notAnsweredCount, setNotAnsweredCount] = useState(0)
  const [notAttendedCount, setNotAttendedCount] = useState(0)
  const [markedAnsweredCount, setMarkedAnsweredCount] = useState(0)

  const [examName, setExamName] = useState("")
  const [totalQuestion, setTotalQuestion] = useState("0")

  const [maxMarks, setMaxMarks] = useState("0")

  const [paramsData, setParamsData] = useState({})

  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  })

  const [questions, setQuestions] = useState([])
  const [testID, setTestID] = useState(0)

  const [reviewType, setReviewType] = useState(REVIEWTYPE[0]);

  useEffect(() => {
    if (backflag == 0) {
      if (data?.state?.id) {
        setReviewType(REVIEWTYPE[0]);
        setParamsData(data)
        bindReviewDetails(REVIEWTYPE[0], data?.state?.id);

      } else {
        navigate('/dashboard')
      }
    } else {
    }
    SetBlackFlag(1)
  }, [data])

  useEffect(() => {
    document.addEventListener("contextmenu", handlecontextmenu)

    const handleKeyDown = (event) => {

        if (event.code === "MetaLeft" && event.keyCode === 91 && event.metaKey === true) {
            event.preventDefault()
        }


        // Ctrl + A 
        if (event.ctrlKey && (event.key === 'a' || event.key === 'A')) {
            // Prevent default behavior for Ctrl+A (select all)
            event.preventDefault();
        }
        // Ctrl + c
        if (event.ctrlKey && (event.key === 'c' || event.key === 'C')) {
            // Prevent default behavior for Ctrl+C (copy)
            event.preventDefault();
        }
        // // Inspect disabled
        if (event.ctrlKey && event.shiftKey && (event.key == 'i' || event.key === 'I')) {
            event.preventDefault();
        }
        if ((event.ctrlKey || event.metaKey) && (event.keyCode === 91)) { // Ctrl + S or Command + S
            event.preventDefault();
            // alert('Screenshots are disabled.');
        }
        if (event.key === "Meta") {
            event.preventDefault();
            // alert('=-=-=')
        }
        if ((event.key === "PrintScreen" || event.key === 'Meta') && (event.keyCode === 44 || event.keyCode === 91)
            && event.metaKey === 'true') {
            event.preventDefault();
            // alert('=-=-=')
        }

    }
    window.addEventListener('keydown', handleKeyDown);
    // Add event listener to capture Ctrl+A and Ctrl+C keydown events
    document.addEventListener('keydown', handleKeyDown);

    // document.addEventListener('keyup', (e) => {
    // console.log('e=====>', e)
    //     if (e.key == 'PrintScreen') {
    //         navigator.clipboard.writeText('');
    //         alert('Screenshots disabled!');
    //     }
    //     if((e.metaKey) && (e.keyCode == 91)){
    //         navigator.clipboard.writeText('');

    //         alert('dis')
    //     }
    //     if (event.key === 's' && event.metaKey && event.shiftKey) {
    //         event.preventDefault(); // Prevent the default behavior
    //         console.log('Windows + Shift + S is restricted');
    //     }
    // });
    document.addEventListener('keydown', function (event) {
        // Check if the key combination is Windows + PrintScreen
        if (event.key === 'PrintScreen' && (event.metaKey || event.ctrlKey)) {
            event.preventDefault(); // Prevent the default behavior
            console.log('Windows + PrintScreen is restricted');
        }

        // Check if the key combination is Windows + Shift + S
        if (event.key === 's' && event.metaKey && event.shiftKey) {
            event.preventDefault(); // Prevent the default behavior
            console.log('Windows + Shift + S is restricted');
        }
    });

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
        // document.removeEventListener("contextmenu",handlecontextmenu)
        document.removeEventListener('keydown', handleKeyDown);
    }


}, [])
const handlecontextmenu = (e) => {
  e.preventDefault()
}

const handleDragStart = (e) => {
  e.preventDefault(); // Prevent default drag behavior
};

  useEffect(() => {

    const disableBackButton = (e) => {
      e.preventDefault(); // Prevent default behavior of back button
      window.history.forward(); // Move forward in history
    };
    // Push a new state to prevent going back
    window.history.pushState(null, "", window.location.href);

    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href)
    }
    // Add an event listener for the popstate event
    // window.addEventListener('popstate', disableBackButton);

    // Cleanup function to remove event listener
    // return () => {
    //   window.removeEventListener('popstate', disableBackButton);
    // };
  }, []);


  async function bindReviewDetails(type, testid) {
    const user = getLoggedInUser()
    const params = {
      "stid": Number(user.UserInfo.stid),
      "testid": Number(testid),
      "type": Number(type.value)
    };


    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'exam/getReviewtemptDetails', params, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await bindReattemptQuestions();
          }
        }
      }

      if (response?.data?.body?.statusFlag === 1) {
        var qaList = response?.data?.body?.examqaodetailslist;
        setQuestions(qaList)
        setNotAttendedCount(qaList.filter(e => e?.attended != 1)?.length);
        setAnsweredCount(qaList.filter(e => e?.answered == 1)?.length);
        setMarkedCount(qaList.filter(e => e?.marked == 1)?.length);
        setNotAnsweredCount(qaList.filter(e => e?.attended == 1 && (e?.answered != 1 && e?.marked != 1))?.length);

        var examReviewDetails = response?.data?.body?.examReviewDetails?.[0];
        setExamName(examReviewDetails?.examname)
        setMaxMarks(examReviewDetails?.test_totalmarks)
        setTotalQuestion(examReviewDetails?.test_totalquestions)
        setTestID(response?.data?.body?.testID)


      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      //console.error("Change Password API Error:", error);
    } finally {

    }
  }

  const btnOnClickHome = () => {
    navigate('/dashboard')
  }
  const onClickNext = (index) => {

    // load next question
    if (activeQuestion !== questions.length - 1) {

      setActiveQuestion(index)

    } else {
      setActiveQuestion(0)
    }
  }





  const onClickPrevious = () => {


    if (activeQuestion !== 0) {
      setActiveQuestion(prev => prev - 1);
    } else {
      setActiveQuestion(questions.length - 1);
    }
  };
  const onClickSubmit = () => {
    navigate("/login")
  };


  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`)

  //meta title
  // document.title = "Masters | Online Test";
  function handlereviewType(review) {
    if (review?.value === 2) {
      setActiveQuestion(0)
      setReviewType(review)
      bindReviewDetails(review, paramsData?.state?.id)
    } else {
      setActiveQuestion(0)

      setReviewType(review)
      bindReviewDetails(review, paramsData?.state?.id)
      addLeadingZero(activeQuestion)
    }


  }

  return (
    <React.Fragment>

      <div className="page-content pt-1">

        <Row>
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :

              <Card >
                <CardBody >
                  <Row>
                    <Col lg="5">
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <h5>{examName}</h5>
                        </div>
                        <div className="text-muted">
                          <h8>
                            Total Questions
                            <span className="m-2">
                              <Badge className="btn btn-blue custom-badge">
                                <b>{totalQuestion}</b>
                              </Badge>
                            </span>
                          </h8>
                          <h8 >Max Score
                            <span className="m-2">
                              <Badge className="btn btn-success custom-badge">
                                <b>{maxMarks}</b>
                              </Badge>
                            </span>
                          </h8>

                        </div>

                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3 ">
                        <Label>Review</Label>
                        <Select
                          value={reviewType}
                          onChange={(selectedOption) => {
                            handlereviewType(selectedOption);
                          }}
                          options={REVIEWTYPE}

                        />
                      </div>
                    </Col>

                    <Col lg="2">


                      <Row>
                        <Col>

                          <div className="activeques" >
                            <span className="active-question-no">{reviewType?.value == 1 ? addLeadingZero(activeQuestion + 1)
                              :
                              reviewType?.value == 2 ? addLeadingZero(activeQuestion + 1) : ""}</span>
                            <span className="total-question">/{addLeadingZero(questions.length)}</span>
                          </div>


                        </Col>

                      </Row>
                    </Col>

                  </Row>
                  <div style={{ borderTop: '1px solid grey', margin: '16px' }}></div>
                  <Row>
                    <Col xs="8">
                      <Row>
                        <Col xs="12">
                          <Row>

                            <div>
                              <Badge pill color="success" className="me-1">
                                <b>{answeredCount}</b> - Answered
                              </Badge>
                              <Badge pill color="btn btn-yellow custom-badge" className="me-1 btn btn-yellow custom-badge">
                                <b>{markedCount}</b> - Marked
                              </Badge>

                              <Badge pill color="btn btn-blue custom-badge" className="me-1 btn btn-blue custom-badge">
                                <b>{notAnsweredCount}</b> -  Not Answered
                              </Badge>
                              <Badge pill color="btn btn-lightblue custom-badge" className="me-1 btn btn-lightblue custom-badge" >
                                <b>{notAttendedCount}</b> - Not Attended

                              </Badge>
                            </div>

                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <div className="quiz-container">
                          {!showResult ? (
                            <div className="m-4">

                              <div className="cls-div-qn">


                                {questions[activeQuestion]?.qt_contentflag == 1 ? <div className='ck-content' dangerouslySetInnerHTML={{
                                  __html: questions[activeQuestion]?.qt_text
                                }} />
                                  : <h2>{questions[activeQuestion]?.qt_text}</h2>}
                                <ul>
                                  {questions[activeQuestion]?.optionList?.map((answer, index) => (
                                    <div className="row" key={answer}>

                                      <li

                                        className={answer?.selectedanswer === 1 && answer?.qa_answerflag === 1 ? 'selected-review-answer col-11' : answer?.qa_answerflag === 1 ? 'selected-review-answer col-11' : answer?.selectedanswer === 1 && answer?.correctanswered === 0 ? 'wrong-answer col-11' : 'col-11'}
                                        style={{ cursor: 'pointer' }}

                                      >
                                        <input
                                          type="radio"
                                          name="answer"

                                          style={{ verticalAlign: 'middle' }}

                                          value={answer?.qaid}
                                          checked={answer?.selectedanswer === 1}
                                          disabled
                                        />


                                        {answer.qa_contentflag == 1 ? <div className='ck-content' dangerouslySetInnerHTML={{ __html: answer.qa_name }} />
                                          : <span> {answer.qa_name}</span>}


                                      </li>
                                    </div>

                                  ))}
                                </ul>
                                {questions[activeQuestion]?.qt_notes != "" ?
                                <div>

                                    <h2>Solution</h2>
                                    <div style={{backgroundColor:"#EEE", padding:8,marginRight:8}}>
                                  
                                  {questions[activeQuestion]?.qt_notes_contentflag == 1 ? <div className='ck-content' dangerouslySetInnerHTML={{
                                      __html: questions[activeQuestion]?.qt_notes
                                    }} />
                                      : <span>{questions[activeQuestion]?.qt_notes}</span>}
                                  </div>

                                </div>:null}
                               
                              </div>
                              
                              <div className={' position-absolute bottom-0 end-0' } style={{ display: 'flex', justifyContent: 'right',marginRight:50 }}>

                                {activeQuestion === 0 ? '' : <Link onClick={onClickPrevious} className="btn btn-soft-primary">Previous</Link>}


                                {activeQuestion === questions.length - 1 ? '' : <Link onClick={() => onClickNext(activeQuestion + 1)} className="btn btn-soft-primary">Next</Link>}
                              </div>

                            </div>
                          ) : (
                            <div className="result">
                              <h3>Result</h3>
                              <p>
                                Total Question: <span>{questions.length}</span>
                              </p>
                              <p>
                                Total Score:<span> {result.score}</span>
                              </p>
                              <p>
                                Correct Answers:<span> {result.correctAnswers}</span>
                              </p>
                              <p>
                                Wrong Answers:<span> {result.wrongAnswers}</span>
                              </p>
                            </div>
                          )}
                        </div>
                      </Row>
                    </Col>
                    <Col xs="4">
                      <Card style={{ width: '100%', height: 'calc(62vh - 100px)', overflowY: 'auto', padding: '20px', marginTop: '1px' }}>
                        <Row>
                          {questions?.map((e, index) => (
                            activeQuestion + 1 == index + 1 ? (
                              <div key={"button_" + index} style={{ width: '10%', background: 'rgb(164 174 229 / 40%)', margin: '3px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }} className="cursor-focus" >
                                {index + 1}
                              </div>
                            )
                              :
                              e.attended == 1 && e.answered != 1 && e.marked != 1 && activeQuestion + 1 != index + 1 ?
                                <div key={"button_" + index} style={{ width: '10%', background: 'blue', margin: '3px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }} className="cursor-focus" onClick={() => onClickNext(index)}>
                                  {index + 1}
                                </div> :

                                e.attended == 1 && e.answered == 1 && e.marked != 1 ?
                                  <div key={"button_" + index} style={{ width: '10%', background: '#34c38f', margin: '3px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }} className="cursor-focus" onClick={() => onClickNext(index)}>
                                    {index + 1}
                                  </div> : e.marked == 1 ?
                                    <div key={"button_" + index} style={{ width: '10%', background: 'orange', margin: '3px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }} className="cursor-focus" onClick={() => onClickNext(index)}>
                                      {index + 1}
                                    </div> : <div key={"button_" + index} style={{ width: '10%', background: '#f0f2fd66', margin: '3px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#000' }} className="cursor-focus" onClick={() => onClickNext(index)}>
                                      {index + 1}
                                    </div>
                          ))}
                        </Row>
                      </Card>


                      <Button className="btn btn-silk custom-badge position-absolute bottom-0 end-0" style={{ marginBottom: '1px' }}  onClick={() => { btnOnClickHome() }}>
                      Back to home
                    </Button>
                      {/* <div className="btn btn-silk custom-badge position-absolute bottom-0 end-0" style={{ marginBottom: '1px' }}>
                        <Button type="button" color="primary " onClick={() => { btnOnClickHome() }}> */}
                          {/* <i className="dripicons-preview font-size-15 align-middle me-2"></i> */}
                          {/* <i className="mdi mdi-file-find font-size-15 align-middle me-2"></i> */}
                          {/* {" "} Back to home */}
                        {/* </Button>
                      </div> */}

                    </Col>
                  </Row>
                </CardBody>
              </Card>


          }
        </Row>


      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
OnlineTestReview.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default OnlineTestReview;
