import React, { useState, useEffect } from "react";
import {
    Card,
    Col,
    Row,
    CardHeader,
    Badge,
    Label

} from "reactstrap"
import { Link, useNavigate, useLocation } from "react-router-dom";
import './ins.css'
import Select from "react-select";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper";
import axios from "axios";
import * as url from "../../../src/helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ONLINE } from '../../constants/commonVariable'
import Spinners from "../../components/Common/Spinner";


const Instructions = (location) => {
    const data = useLocation();

    const navigate = useNavigate();
    const [examlevel, setexamlevel] = useState(null);
    const user = getLoggedInUser();
    const [instructions, setinstructions] = useState([]);
    const [studentdetails, setstudentdetails] = useState([]);
    const [subjectid, setsubjectid] = useState("");
    const [subjectid1, setsubjectid1] = useState("");
    const [exctid, setexctid] = useState("");
    const [testname, settestname] = useState("");
    const [isLoading, setLoading] = useState(false);

    const [examName, setExamName] = useState("")
    const [totalMarks, setTotalMarks] = useState(0)
    const [totalQuestion, setTotalQuestion] = useState(0)
    const [totalDuration, setTotalDuration] = useState(0)

    const [type, setType] = useState(null)
    const optionexlevel = instructions.map((instruction) => ({
        label: instruction.exl_name,
        value: instruction.exlid,
    }));

    function handleexamlevel(examlevel) {
        setexamlevel(examlevel);
    }


    // const history = useHistory();

    //   useEffect(() => {
    //     const disableBackButton = (e) => {
    //       e.preventDefault();
    //       history.go(1); // Go forward to the current page again
    //     };

    //     window.addEventListener('popstate', disableBackButton);

    //     return () => {
    //       window.removeEventListener('popstate', disableBackButton);
    //     };
    //   }, [history]);
    //     useEffect(() => {
    //         const disableBackButton = (event) => {
    //           event.preventDefault();
    //           navigate.push('/'); // Redirect to the home page or any other page
    //         };
    //         window.history.pushState(null, null, window.location.pathname);
    //         window.addEventListener('popstate', disableBackButton);

    //         return () => {
    //           window.removeEventListener('popstate', disableBackButton);
    //         };
    //   }, []);
    useEffect(() => {

        // const disableBackButton = (e) => {
        //     e.preventDefault(); // Prevent default behavior of back button
        //     window.history.forward(); // Move forward in history
        //   };

        //   // Push a new state to prevent going back
        //   window.history.pushState(null, null, window.location.pathname);

        //   // Add an event listener for the popstate event
        //   window.addEventListener('popstate', disableBackButton);

        if (data?.state?.id) {

            InstructionList(data.state.id, data?.state?.type)
        } else {
            navigate('/dashboard')
        }
        // Cleanup function to remove event listener
        //   return () => {
        //     window.removeEventListener('popstate', disableBackButton);
        //   };
    }, []);

    useEffect(() => {

        const disableBackButton = (e) => {
            e.preventDefault(); // Prevent default behavior of back button
            window.history.forward(); // Move forward in history
        };
        // Push a new state to prevent going back
        window.history.pushState(null, "", window.location.href);

        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href)
        }
        // Add an event listener for the popstate event
        // window.addEventListener('popstate', disableBackButton);

        // Cleanup function to remove event listener
        // return () => {
        //   window.removeEventListener('popstate', disableBackButton);
        // };
    }, []);



    useEffect(() => {

        if (data?.state?.id) {
            setexctid(data.state.exctid)
            settestname(data.state.testname)
            setType(data?.state?.type)
            setsubjectid1(data?.state?.mk_sid)
            setsubjectid(data.state.id)
            InstructionList(data.state.id, data?.state?.type)
        } else {
            navigate('/dashboard')
        }
    }, [data?.state])

    const InstructionList = async (id, type) => {
        setLoading(true)
        const data = {
            "id": Number(id),
            stid: Number(user.UserInfo.stid),
            "type": type
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

        try {
            const response = await axios.post(url.BASE_URL + 'master/getinstructionspage', data, config);
            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response.data.body.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser();
                    } else {
                        await InstructionList(mkid);
                    }
                }
            }

            if (response?.data?.body) {
                setinstructions(response.data.body.stu_examlevel_options);

                var configuration = response.data.body?.examconfigurationlist[0]
                setExamName(configuration?.c_name)
                setTotalMarks(configuration?.c_totalmarks)
                setTotalQuestion(configuration?.c_totalquestions)
                setTotalDuration(configuration?.c_duration)

            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("student details API Error:", error);
        }
    };

    const backbutton = () => {

        if (type == 0) {
            navigate("/subjectwisemocktest", { state: { subjectid: subjectid, testname: testname, exctid: exctid, statusFlag: 1, expid: data?.state?.expid, sid: subjectid1 } });

        } else if (type == 1) {
            navigate("/Topicwisemocktest", { state: { subjectid: subjectid, testname: testname, exctid: exctid, expid: data?.state?.expid, sid: subjectid1, statusFlag: 2, sno: data?.state?.sno } });

        }
        else if (type == 2) {
            navigate("/fullLengthtest")
        }
    }
    const nextclick = () => {
        // if (examlevel == null || examlevel == undefined || examlevel == "") {
        //     toast.error(ONLINE.EXAMLEVELVALIDATE, { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
        // } else {
        // console.log('exam le', examlevel)
        const params = {
            cid: Number(subjectid), exlid: 0, flag: type, "stid": Number(user.UserInfo.stid),
        }
        if (params) {
            // levelchecking(params)
            navigate("/onlinetest", { state: { id: subjectid, exlid: examlevel?.value, type: type } });


        }

        // navigate("/onlinetest", { state: { id: subjectid, exlid: examlevel?.value, type: type } });
        // }


    }

    async function levelchecking(params) {
        const user = getLoggedInUser()
        setLoading(true)
        // const params = {
        //   "stid": Number(user.UserInfo.stid),
        //   // "stid": 50,
        //   "cid": Number(data?.state?.id),
        //   "flag": data?.state?.type,
        //   "exlid": data?.state?.exlid
        // };

        const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

        try {
            const response = await axios.post(url.BASE_URL + 'master/levelchecking', params, config);
            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response.data.body.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser();
                    } else {
                        await levelchecking();
                    }
                }
            }
            if (response?.data?.body?.statusFlag === 1) {

                navigate("/onlinetest", { state: { id: subjectid, exlid: examlevel?.value, type: type } });

            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
            setLoading(false)
        } catch (error) {
            // Handle error
            //console.error("Change Password API Error:", error);
        } finally {

        }
    }


    return (
        <React.Fragment>
            <Row>
                {isLoading ?
                    <Spinners setLoading={setLoading} /> :
                    <Col lg={12}>
                        <Col lg={12}>
                            <Card className="cardRightSide">
                                <CardHeader className="cardHead">
                                    <Row className="align-items-center">
                                        <Col lg={6} className="mt-4">
                                            <h5>{examName}</h5>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="d-flex justify-content-end">
                                                <h8 className="cardtopalign">
                                                    Total Marks:
                                                    <span className="leftalignspa">
                                                        <Badge className="btn btn-blue custom-badge" style={{ marginRight: '10px', borderRadius: '53px', marginTop: '7%' }}>
                                                            <b>{totalMarks}</b>
                                                        </Badge>
                                                    </span>
                                                </h8>
                                                <h8 className="cardtopalign1">Total Questions:
                                                    <span className="leftalignspa">
                                                        <Badge className="btn btn-success custom-badge" style={{ marginRight: '10px', borderRadius: '53px' }}>
                                                            <b>{totalQuestion}</b>
                                                        </Badge>
                                                    </span>
                                                </h8>
                                                <h8 className="cardtopalign1">Duration:
                                                    <span className="leftalignspa">
                                                        <Badge className="btn btn-purple custom-badge" style={{ marginRight: '10px', borderRadius: '53px' }}>
                                                            <b >{totalDuration} mins</b>
                                                        </Badge>
                                                    </span>
                                                </h8>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <div style={{ borderTop: '1px solid grey', margin: '10px 0' }}></div>

                                <div className="paddingrightleft">
                                    {/* <Col lg={2}>
                                        <div className="mb-3 ">
                                            <Label>Exam Level</Label>
                                            <Select
                                                value={examlevel}
                                                onChange={(selectedOption) => {
                                                    handleexamlevel(selectedOption);
                                                }}
                                                options={optionexlevel}
                                            />
                                        </div>
                                    </Col> */}
                                    <Row>

                                        <Col lg={10}>
                                            <h5 className="headingspace">General Instructions:</h5>
                                            <p className="extras">1. The clock will be set at the server. The countdown timer at the top right corner of screen will display the remaining time available for you to complete the examination. When the timer reaches zero,<br /> the examination will end by itself. You need not terminate the examination or submit your paper.</p>
                                            <p className="extras">2. The Question palette displayed on the right side of screen will show the status of each question.</p>
                                            <p className="extras">3. To answer a question, do the following,</p>
                                            <ul className="extras-space">
                                                <li>Click on the question number in the Question palette at the right of your screen to go to that numbered question directly.</li>
                                                <li>Click on Next to save your answer for the current question and then go to the next question.</li>
                                                <li>Click on Mark for Review to save your answer for the current question and also mark it for review, and then go to the next question.</li>
                                            </ul>
                                            <p className="extras">
                                                4. Mark for Review status for a question simply indicates that you would like to look at that question again.<br />
                                                If a question is answered, but marked for review, then the answer will be considered for evaluation unless the status is modified by the candidate.
                                            </p>
                                        </Col>
                                    </Row>
                                    <div className="input-group-append" style={{ textAlign: "end" }}>
                                        <button className="btn btn-danger btnspace" type="submit"
                                            onClick={() => {
                                                backbutton()
                                            }}
                                        >
                                            Back
                                        </button>
                                        <button className="btn btn-primary" type="submit" onClick={() => {

                                            nextclick()
                                        }}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Col>
                }
            </Row>
            <ToastContainer />

        </React.Fragment>
    )
}
export default Instructions