import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";


// Dashboard
import Dashboard from "../pages/Dashboard/index";
import StudyMaterial from "../pages/StudyMaterials/sm-index"

import Onlinetest from "../pages/onlinetest/index"
import Subjectwisemockup from "../pages/subjectwisemocktest/index"
import Fulllength from "../pages/Fulllengthmocktest/index"
import Topicwisemockuptest from "../pages/Topicwisemocktest/index"
import Instructionpage from "../pages/Instructions/index"
import Changepassword from "../pages/Authentication/Changepassword"
import StudyMaterialView from "../pages/StudyMaterials/sm-view"
import Summary from "pages/onlinetest/summary"
import VerifyEmail from 'pages/Authentication/page-confirm-mail'
import ForgorVerifyEmail from 'pages/Authentication/auth-email-verification'
import ConfirmPassword from 'pages/Authentication/ConfirmPassword'
import OnlineTestReview from "pages/onlinetest/review";
import RedirectScreen from "pages/Authentication/redirectscreen";
const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/studymaterials", component: <StudyMaterial /> },
  { path: "/studymaterialview", component: <StudyMaterialView /> }, 
  // //profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/onlinetest", component: <Onlinetest /> },
  { path: "/subjectwisemocktest", component: <Subjectwisemockup /> },
  { path: "/topicwisemocktest", component: <Topicwisemockuptest /> },
  { path: "/fullLengthtest", component: <Fulllength /> },
  { path: "/Instructionspage", component: <Instructionpage /> },
  { path: "/Changepassword", component: <Changepassword /> },
  { path: "/summary", component: <Summary /> },
{path: "/resetpassword", component: <RedirectScreen/>},
  { path: "/reviewtest", component: <OnlineTestReview /> },

 
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/onlineTest" />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/Instructionspage" />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/subjectwisemocktest" />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/topicwisemocktest" />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/fullLengthtest" />,
  },
  {
    path: '/studymaterials/:tid',
    exact: true,
    component: <Navigate to="/studymaterials" />,
  }
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/verificationemail/:emailid", component: <VerifyEmail /> },
  { path: "/forgot-verify-email", component: <ForgorVerifyEmail /> },
  { path: "/confirm-password/:emailid", component: <ConfirmPassword /> },
  { path: "/resetpassword", component: <RedirectScreen/>}
 
];

export { authProtectedRoutes, publicRoutes };
