import PropTypes from "prop-types";
import React from "react";
import { Container, Row, Col } from "reactstrap"; 
import Subjectwise from "./SubjectWiseCat";

//Import Breadcrumb
import EBBreadcrumbs from "../../components/Common/EBBreadcrumbs";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import Subjectcontent from "./SubjectWise";

const Subject = props => {
  //meta title
  document.title = "Subject Wise Mock Test | Skote - React Admin & StudyMaterials Template";

  return (
    <React.Fragment>
  
  <div className="page-content pt-3">
      <Container fluid>
      <Breadcrumb  breadcrumbItem="Subject Wise MockUp Test" />
             <Row>
          
          {/* <Col lg='2'>
        
          <Subjectwise />

          </Col>   */}
          <Col lg='12'>
          <Subjectcontent />
            </Col>        
                 
        </Row>
      </Container>
    </div>
  </React.Fragment>
  );
};

Subject.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Subject);
