import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper";
import axios from "axios";
import * as url from "../../../src/helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/customstyle.css';

// import {
//   Button,
//   Card,
//   Col,

//   Row,
//   Nav,

//   NavItem,

// } from "reactstrap";
import {
  Table,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Collapse
} from "reactstrap";
import Spinners from "../../components/Common/Spinner";
import layout2 from "../../assets/images/nodatafound.jpg";

//Import Breadcrumb
import EBBreadcrumbs from "../../components/Common/EBBreadcrumbs";

import classnames from "classnames";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Paginations from '../../components/Common/Pagination';

import './Topic.scss'
const TopicContent = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [exboardexcategorylist, setExboardexcategorylist] = useState([]);
  const [loadStatus, setLoadStatus] = useState(true);
  const [params1, setParams] = useState([])
  const [boardCategoryTopicList, setboardCategoryTopicList] = useState([]);
  const [boardCategoryList, setBoardCategoryList] = useState([])
  const [allboardCategoryList, setAllBoardCategoryList] = useState([])
  const [Subject, setSubject] = useState([]);
  const [topicList, setTopicList] = useState([])
  const [topicfilterList, setfilterTopicList] = useState([])
  const [activeSubjectID, setActiveSubjectID] = useState(0);

  // pagination
  // const [jobGridData, setJobGridData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const perPageData = 8;
  const indexOfLast = currentPage * perPageData;
  const indexOfFirst = indexOfLast - perPageData;
  const currentData = useMemo(() => boardCategoryList?.slice(indexOfFirst, indexOfLast), [boardCategoryList, indexOfFirst, indexOfLast])
  const data = useLocation();

  const [activeTab, setActiveTab] = useState(0);
  const [modal, setModal] = useState(false);
  const [badgeLength, setBadgeLength] = useState();
  const [isOpen, setIsOpen] = useState(true)
  const [activeGroupName, setActiveGroupName] = useState("");
  const [activeSubjectName, setActiveSubjectName] = useState("");
  const [activeSubjectTab, setActiveSubjectTab] = useState("1");
  const [activePaperName, setActivePaperName] = useState("");

  const toggle = () => setIsOpen(!isOpen);

  const user = getLoggedInUser()

  useEffect(() => {
    const data = localStorage.getItem('authUser')
    const parsevalue = JSON.parse(localStorage.getItem('authUser'))


    if (parsevalue?.UserInfo?.stu_interested[0]?.mstid == 2) {
      fetchData();

    } else if (parsevalue?.UserInfo?.stu_interested[0]?.mstid == 1 && parsevalue?.UserInfo?.stu_interested[1]?.mstid == 2) {
      fetchData();

    } else if (parsevalue?.UserInfo?.stu_interested[1]?.mstid == undefined || parsevalue?.UserInfo?.stu_interested[0]?.mstid != 2) {

      navigate('/dashboard')
    }

    //   if(parsevalue?.UserInfo?.stu_interested[0]?.mstid != 2){
    // setLoading(true)
    //       navigate('/dashboard')

    //     }else if(parsevalue?.UserInfo?.stu_interested[0]?.mstid == 2){
    //     fetchData();
    //   }
  }, []);



  const fetchData = async () => {
    setLoading(true)

    var boardcatArr = [];

    const data = {
      stid: Number(user.UserInfo.stid),
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'master/gettopics_subjectlist', data, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await fetchData();
          }
        }
      }
      if (response?.data?.body?.exboardexcategorylist) {

        boardcatArr = response.data.body.exboardexcategorylist;
        bindMenuArray(boardcatArr)
        // setBoardCategoryList(response.data.body.studymaterialsubjectlist);

      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      console.error("student detailsAPI Error:", error);
    } finally {

    }
  }

  const bindMenuArray = (boardcatArr) => {
    const user = getLoggedInUser()
    if (loadStatus && boardcatArr && boardcatArr.length > 0) {
      setActiveTab("1")
      var categoryObj = {}
      var result = boardcatArr.reduce(function (r, el) {
        var mk_exctid = el.mk_exctid;
        if (!categoryObj[mk_exctid]) {
          categoryObj[mk_exctid] = {
            mk_exctid: el.mk_exctid,
            examboardcategory: el.examboardcategory,
            isopen: false,
            exampaper: []
          }
          r.push(categoryObj[mk_exctid]);
        }
        var obj = {
          sno: el.sno,
          mk_exctid: el.mk_exctid,
          mk_expid: el.mk_expid,
          exp_name: el.exp_name,
          isopen: false,
          sid: el.sid,
          // tid: el.tid,
          examboardcategory: el.examboardcategory,
          s_name: el.s_name,
          // t_count: el.t_count
        }
        categoryObj[mk_exctid].exampaper.push(obj);

        return r;
      }, [])

      var examdetailsarray = []
      for (var i = 0; i < result.length; i++) {
        var subObj = {}
        var j = 0;
        var subjectresult = result[i].exampaper.reduce(function (r, el) {
          j = j + 1;
          var mk_expid = el.mk_expid;
          if (!subObj[mk_expid]) {
            subObj[mk_expid] = {
              sno: el.sno,
              mk_exctid: el.mk_exctid,
              mk_expid: el.mk_expid,
              exp_name: el.exp_name,
              examboardcategory: el.examboardcategory,
              isopen: j === 1 ? true : false,
              subjectList: []
            }
            r.push(subObj[mk_expid]);
          }
          var obj = {
            sno: el.sno,
            mk_exctid: el.mk_exctid,
            mk_expid: el.mk_expid,
            exp_name: el.exp_name,
            isopen: j === 1 ? true : false,
            sid: el.sid,
            // tid: el.tid,
            examboardcategory: el.examboardcategory,
            exp_name: el.exp_name,
            s_name: el.s_name,
            // t_count: el.t_count
          }
          subObj[mk_expid].subjectList.push(obj);
          return r;
        }, [])
        var finalobj = {
          mk_exctid: result[i].mk_exctid,
          examboardcategory: result[i].examboardcategory,
          isopen: i == 0 ? true : false,
          exampaper: subjectresult
        }
        examdetailsarray.push(finalobj);
      }
      setBoardCategoryList(examdetailsarray)
      setAllBoardCategoryList(examdetailsarray)
      setActiveSubjectID(examdetailsarray[0].exampaper[0].subjectList[0].sno)

      const params = {
        "sid": Number(examdetailsarray[0].exampaper[0].subjectList[0].sid),
        "mk_exctid": Number(examdetailsarray[0].exampaper[0].mk_exctid),
        "stid": Number(user.UserInfo.stid),
        "mk_expid": Number(examdetailsarray[0].exampaper[0].mk_expid)
      }
      listTopicMaterialSubjectList(params)
      setActiveGroupName(examdetailsarray[0].examboardcategory);
      setActivePaperName(examdetailsarray[0].exampaper[0].exp_name);
      setActiveSubjectName(examdetailsarray[0].exampaper[0].subjectList[0].s_name);

      setLoadStatus(false)
    }
  }


  useEffect(() => {
    if (data?.state?.statusFlag === 2 && boardCategoryList && boardCategoryList.length > 0) {


      if (boardCategoryList.length > 0) {
        var activeCategory = boardCategoryList.map(e => {
          if (e?.mk_exctid == data?.state?.exctid) {
            e['isopen'] = true
            e.exampaper.map(f => {
              if (f.mk_exctid == data?.state?.exctid && f.mk_expid == data?.state?.expid) {

                f['isopen'] = true
                setActiveSubjectID(data?.state?.sno)
              }
              else {
                f['isopen'] = false
              }
            })
          }
          else {
            e['isopen'] = false

            e.exampaper.map(f => {
              f['isopen'] = false
            })
          }
          return e
        })
        setBoardCategoryList(activeCategory)
      }

    }
  }, [allboardCategoryList, data?.state?.statusFlag])

  const listTopicMaterialSubjectList = async (params) => {
    // setLoading(true)

    var boardcatArr = [];
    const user = getLoggedInUser()

    const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'master/getModelExamInstructions', params, config);
      if (response && response?.data?.body?.statusFlag === 3) {
        toast.error(response.data.body.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await listTopicMaterialSubjectList(params);
          }
        }
      }
      if (response?.data?.body?.stu_examdetailslist) {
        setSubject(response.data.body.stu_examdetailslist);
        boardcatArr = response.data.body.stu_examdetailslist;
        bindMenuArray1(boardcatArr)

      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      console.error("student detailsAPI Error:", error);
    } finally {

    }
  }

  const bindMenuArray1 = (boardcatArr) => {
    const user = getLoggedInUser()
    setParams(Number(user.UserInfo.stid))
    setTopicList(boardcatArr);
    setfilterTopicList(boardcatArr);

  }



  // useEffect(() => {
  //     setJobGridData(currentData);
  // }, [currentData]);

  const colorArray = [
    { "badge_color": "text-bg-info", "bg_color": "btn-soft-info" },
    { "badge_color": "text-bg-warning", "bg_color": "btn-soft-warning" },
    { "badge_color": "text-bg-success", "bg_color": "btn-soft-success" },
    { "badge_color": "text-bg-secondary", "bg_color": "btn-soft-secondary" },
    { "badge_color": "text-bg-primary", "bg_color": "btn-soft-primary" },
    { "badge_color": "text-bg-dark", "bg_color": "btn-soft-dark" },
  ]





  // useEffect(() => {
  //     if (exboardexcategorylist && exboardexcategorylist.length > 0) {
  //         setActiveTab(0)
  //         setActiveGroupName(boardCategoryTopicList[0].s_name);
  //     }
  // }, [exboardexcategorylist]);

  useEffect(() => {
    if (exboardexcategorylist && exboardexcategorylist.length > 0) {
      setActiveSubjectTab(0)
      setActiveSubjectName(exboardexcategorylist[0].s_name);
    }
  }, [exboardexcategorylist]);


  const onClickBoardCategory = (row) => {

    if (boardCategoryList && boardCategoryList.length > 0) {
      const boardCategoryList_arr = Object.assign([], boardCategoryList)
      const result = []
      boardCategoryList_arr.forEach(i => {
        const obj = { ...i }
        if (i.mk_exctid === row.mk_exctid) {
          if (row.isopen) {
            obj['isopen'] = false
          } else {
            obj['isopen'] = true
          }
        } else {
          obj['isopen'] = false
        }
        result.push(obj)
        setBoardCategoryList(result)
      })
    }
  }


  const onClickExamPaper = (row) => {
    if (boardCategoryList && boardCategoryList.length > 0) {
      const boardCategoryList_arr = Object.assign([], boardCategoryList)
      const result = []
      const subresult = []
      boardCategoryList_arr.forEach(i => {
        const examPaperLisArr = Object.assign([], i.exampaper)
        const result1 = []
        const obj = { ...i }
        examPaperLisArr.forEach(j => {

          const obj1 = { ...j }
          if (j.mk_exctid === row.mk_exctid && j.mk_expid === row.mk_expid) {
            if (j.isopen) {
              obj1['isopen'] = false
            } else {
              obj1['isopen'] = true
              setActivePaperName(row.exp_name);
              setActiveSubjectName(row.subjectList[0].s_name);
            }
          }
          result1.push(obj1)
          subresult.push(obj1)
        })
        obj['exampaper'] = result1
        result.push(obj)
        setBoardCategoryList(result)
      })
    }
  }

  const toggleVertical = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  function onClickSubject(r) {
    setIsOpen()
    setActiveSubjectID(r.sno)

    const params = {
      "mk_exctid": Number(r.mk_exctid),
      "sid": Number(r.sid),
      "stid": Number(user.UserInfo.stid),
      "mk_expid": Number(r.mk_expid)
    }

    setTopicList(params)
    setParams(Number(user.UserInfo.stid))

    listTopicMaterialSubjectList(params);


  }
  const reattempt = (item) => {

    // if(item){

    navigate("/onlinetest", { state: { id: item.testid, type: 1, reattempt: 1, cid: item.mkid } });
    // }else{
    // navigate("/dashboard")
    // }
  };
  const resume = (item) => {
    // if(item){

    navigate("/onlinetest", { state: { id: item.testid, type: 1, resume: 1, cid: item.mkid } });
    // }else{
    // navigate("/dashboard")
    // }

  };
  const getMenuContent = (item, index) => {
    setActiveSubjectID(item.sno)
    setActiveTab(index)
    setActiveSubjectTab(index)
    setActiveGroupName(item.examboardcategory);
    setActiveSubjectName(item.exp_name);
  }


  const handleClick = (item) => {

    if (item.subjectid === 3) {
      navigate("/Instructionspage", { state: { id: item.mkid, type: 1, exctid: item.mk_exctid, testname: item.mk_testname, expid: item.mk_expid, mk_sid: item.mk_sid, sno: activeSubjectID } });
    } else {
      navigate("/Instructionspage", { state: { id: item.mkid, type: 1, exctid: item.mk_exctid, testname: item.mk_testname, expid: item.mk_expid, mk_sid: item.mk_sid, sno: activeSubjectID } });
    }
  };

  const review = (item) => {

    navigate('/reviewtest', { state: { id: Number(item.testid) } })
  };
  return (
    <React.Fragment>


      <Row>
        {isLoading ?
          <Spinners setLoading={setLoading} /> :
          <Col lg={12} >
            {
              Subject && boardCategoryList && Subject.length > 0 && boardCategoryList.length > 0 ?
                <CardBody >


                  <Card className="email-leftbar">
                    <Button type="button" color="danger" block>Exam Board Categories</Button>
                    <div className="mail-list mt-1">
                      <ul className="list-unstyled categories-list">
                        {
                          (boardCategoryList || []).map((item, index) => (
                            <li key={index}>
                              <div className="custom-accordion">
                                <Link
                                  className="text-body fw-medium py-1 d-flex align-items-center"
                                  onClick={() => {
                                    onClickBoardCategory(item)
                                  }}
                                  to="#"
                                >
                                  <i className="bx bx-book-open smgroup me-2"></i>{item?.examboardcategory}

                                  <i
                                    className={
                                      item.isopen
                                        ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                        : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                    }
                                  />
                                </Link>
                                <Collapse isOpen={item?.isopen}>
                                  <div className="card border-0 shadow-none ps-2 mb-0">
                                    <ul className="list-unstyled mb-0">
                                      {
                                        (item.exampaper || [])?.map((f, e_index) => (

                                          <li key={e_index}>
                                            <div className="custom-accordion">
                                              <Link
                                                className="text-body fw-medium py-1 d-flex align-items-center font-size-11"
                                                onClick={() => {
                                                  setActiveTab(e_index);
                                                  onClickExamPaper(f)
                                                }}
                                                to="#"
                                              >
                                                {f.exp_name}
                                                <i
                                                  className={
                                                    f.isopen
                                                      ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                                      : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                                  }
                                                />
                                              </Link>
                                              <Collapse isOpen={f.isopen}>
                                                <div className="card border-0 shadow-none ps-2 mb-0">
                                                  <ul className="list-unstyled mb-0 subject">
                                                    {
                                                      (f.subjectList || [])?.map((t, s_index) => (

                                                        // <li key={index} onClick={() => {
                                                        //   getMenuContent(t, index)
                                                        // }}>
                                                        <li className={activeSubjectID === t.sno && f.isopen ? 'subject-active' : 'subject-li'} key={s_index} onClick={() => {
                                                          getMenuContent(t, s_index)
                                                        }}>
                                                          <Link to="#" className="d-flex align-items-center font-size-11 sub_li"
                                                            onClick={() => {
                                                              onClickSubject(t)
                                                            }}
                                                          >
                                                            <span className="me-auto">{t.s_name}</span>
                                                            {t.t_count && <span className="badge bg-success rounded-pill p-1 ms-2">{t.t_count}</span>}
                                                          </Link>
                                                        </li>
                                                      ))
                                                    }
                                                  </ul>
                                                </div>
                                              </Collapse>
                                            </div>
                                          </li>
                                        ))
                                      }
                                    </ul>
                                  </div>
                                </Collapse>
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                    </div>



                  </Card>
                  <Row>

                    <Col md="12" className="mainpage1">
                      <TabContent

                        className="text-muted mt-4 mt-md-0"
                      >

                        <TabPane>
                          {
                            Subject && Subject.length > 0 ?

                              <Row>
                                {(Subject || []).map((item, key) => (
                                  <Col xl={4} md={6} key={key}>
                                    <Card>
                                      <CardBody>
                                        <Row>
                                          <Col lg="11">

                                            <h5 className="fs-17 mb-2">
                                              <div className="text-dark">{item.mk_testname}</div> </h5>
                                          </Col>
                                          <Col lg="1">
                                            {item?.testid != null && item?.test_completed == 1 && <Col lg="1">
                                              <div className="reviebutton1">
                                                <Button type="button" color="primary" className="revbutt" onClick={() => { review(item) }}>
                                                  <i className="dripicons-preview font-size-15 align-middle me-1"></i>
                                                  {/* <i className="mdi mdi-file-find font-size-15 align-middle me-2"></i> */}
                                                  {" "}

                                                </Button>

                                              </div>
                                            </Col>}
                                          </Col>
                                        </Row>
                                        <ul className="list-inline mb-0">

                                          <li className="list-inline-item" style={{ width: '100%' }}>
                                            <Row>
                                              <Col lg="9">
                                                <p className="text-muted fs-14 mb-0"><i className="mdi mdi-clock-time-four-outline"></i> {item.mk_duration}</p>
                                              </Col>
                                              {/* {item?.testid != null && item?.test_completed == 1 && <Col lg="3">
                    <div className="reviebutton">
                  <Button type="button" color="primary"  onClick={() => { review(item) }}>
                  <i className="dripicons-preview font-size-15 align-middle me-2"></i>
                  {" "}
                      Review
                    </Button>

                    </div>
                  
                  </Col>} */}

                                            </Row>
                                          </li>



                                        </ul>
                                        <hr className="line3" />
                                        <div className="mt-3 hstack gap-2">

                                          <div className="col scorecolval">

                                            <div>


                                              <p className="text-muted mb-0 scorealign"><i className="fas fa-layer-group iconspace"></i>

                                                Max Score

                                              </p>
                                              <p className="text-muted mb-0 scores">

                                                <p className='cardnumber1'>
                                                  {item.mk_totalmarks} </p>   </p>
                                            </div>
                                          </div>
                                          <div className="col quescolval">

                                            <div>


                                              <p className="text-muted mb-0 quesalign"><i className="dripicons-question iconspace"></i>
                                                Questions




                                              </p>

                                              <p className="text-muted mb-0 scores">

                                                <p className='cardnumber1'>
                                                  {item.mk_totalquestions}  </p>   </p>
                                            </div>
                                          </div>
                                          <div className="col durationcolval">
                                            <div className="text-right rightalign">
                                              <p className="text-muted mb-0 duralign"><i className="dripicons-question iconspace"></i>Mark/Question</p>
                                              <p className="text-muted mb-0 scores">

                                                <p className='cardnumber1'>
                                                  {item.mks_marks}</p>   </p>

                                            </div>

                                          </div>


                                        </div>
                                        {/* <div>


              {item?.testid != null && item?.test_completed == 1 ? (
                <div className="btnModalDesign"><button onClick={() => reattempt(item)} className="btn btn-soft-success w-50">
                  Reattempt
                </button>
                  <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-50">
                    ReTest
                  </button></div>
              ) : null}

              {item?.testid != null && item?.isresumed == 1 ? (



                <div className="btnModalDesign">   <button onClick={() => resume(item)} className="btn btn-soft-danger w-100">
                  Resume
                </button>
                  <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-50">
                    ReTest
                  </button></div>


              ) : null}
              {item.testid == null ? (
                <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-100">
                  Start Test
                </button>
              ) : null}
            </div> */}
                                        <div>
                                          {item?.testid != null && item?.test_completed == 1 ? (
                                            <div
                                            className={item?.isnewtest == 0  ? "btnModalDesign1" : "btnModalDesign"}
                                            // className="btnModalDesign"
                                            ><button onClick={() => reattempt(item)} 
                                            // className="btn btn-soft-success w-50"
                                            className= {item?.isnewtest == 0  ? "btn btn-soft-success w-100" : 
                                            "btn btn-soft-success w-50"
                                             }
                                            >
                                              Reattempt
                                            </button>
                                            {item?.isnewtest == 1 ?
                                              <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-50">
                                                New test
                                              </button>
                                              : "" }
                                              </div>
                                          ) : null}

                                          {item?.testid != null && item?.isresumed == 1 ? (

                                            <div 
                                            className={item?.isnewtest == 0  ? "btnModalDesign1" : "btnModalDesign"}
                                            // className="btnModalDesign"
                                            >   <button onClick={() => resume(item)} 
                                            className=
                                        
                                        {item?.isnewtest == 0  ? "btn btn-soft-danger w-100" : 
                                        "btn btn-soft-danger w-50"
                                         }
                                            // className="btn btn-soft-danger w-50"
                                            >
                                              Resume
                                            </button>
                                            {
item?.isnewtest == 1 ?
                                              <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-50">
                                                New test
                                              </button>
                                              :"" }
                                              </div>


                                          ) : null}

                                          {item.testid == null ? (
                                            <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-100">
                                              Start Test
                                            </button>
                                          ) : null}
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                ))}
                              </Row>
                              :
                              <div className="imgalignno">

                                <img src={layout2} alt="Girl in a jacket" width="380" height="380"></img>
                              </div>

                          }

                          {/* <Paginations
                            perPageData={perPageData}
                            data={Subject}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            isShowingPageLength={true}
                            paginationDiv="col-sm-12 col-md-7"
                            paginationClass="pagination justify-content-end pagination-rounded"
                          /> */}
                        </TabPane>

                      </TabContent>
                    </Col>
                  </Row>
                </CardBody> :
                <div className="imgalignno">

                  <img src={layout2} alt="Girl in a jacket" width="450" height="450"></img>
                </div>

            }



          </Col>
        }

      </Row>

    </React.Fragment >
  );
};

export default withRouter(TopicContent);
