import PropTypes from "prop-types";
// import React from "react";
import { Container, Row, Col } from "reactstrap"; 



//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import React, { useEffect } from "react";

//i18n
import { withTranslation } from "react-i18next";
import Instructionspage from "./Instructionspage";
import { useNavigate} from "react-router-dom";

const Instructions = (props) => {
  //meta title
  // const navigate = useNavigate();
  //document.title = "Topic Wise Mock Test | Skote - React Admin & StudyMaterials Template";
//   useEffect(() => {
//     const disableBackButton = (event) => {
//       event.preventDefault();
//       navigate.push('/'); // Redirect to the home page or any other page
//     };
//     window.history.pushState(null, null, window.location.pathname);
//     window.addEventListener('popstate', disableBackButton);

//     return () => {
//       window.removeEventListener('popstate', disableBackButton);
//     };
// }, []);
  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
       
             <Row>
         
          <Col lg='12'>
            <Instructionspage />
            </Col>        
                 
        </Row>
      </Container>
    </div>
  </React.Fragment>
  );
};

Instructions.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Instructions);
