import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col } from "reactstrap";
import authOverlay from "../../assets/images/loginbg.jpg";
import axios from "axios";
import * as url from "../../../src/helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import SlideDark from "./slidedark-carousel";
import profileImg from "../../assets/images/profile-img.png"

const CarouselPage = () => {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    quickLinksList();
  }, []);

  const quickLinksList = async () => {
    try {
      const response = await axios.post(url.BASE_URL + 'master/quickLinks');
      if (response?.data?.body?.quickLinks) {
        var filtered_links = response?.data?.body?.quickLinks.filter(e => e.gsql_stsid == 1)
        setLinks(filtered_links);
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      console.error("student details API Error:", error);
    }
  };

  return (
    <React.Fragment>
    <Col xl={9}>
      <div className="auth-full-bg pt-lg-5 p-4">
        <div className="w-100">
          <div className="bg-overlay" style={{ background: `url(${authOverlay})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
          <div className="d-flex h-100 flex-column">
            <div className="p-4 mt-auto">
              <div className="row justify-content-center">
                <div className="col-lg-7">
                  <div className="text-center">
                    <div dir="ltr">
                    {/* <VideoLink Videolink={Videolink} /> : <img src={profileImg} alt="" className="img-fluid" />} */}

                    {links && links.length > 0 ?
                      <SlideDark Videolink={links} /> :""}
                      {/* <Carousel infiniteLoop={true} autoPlay={true} className="owl-carousel owl-theme auth-review-carousel slider_css" id="auth-review-carousel" showThumbs={true}>
                        {links.map((link, index) => (
                          <div key={index}>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4 carousel-text"> 
                                  <a href={link.gsql_link} target="_blank" rel="noopener noreferrer" className="text-white font-weight-bold">{link.gsql_alttext}</a>
                                  
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Carousel> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  </React.Fragment>
  )
}
export default CarouselPage;