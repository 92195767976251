import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Trio-S.</Col>
            <Col md={6}>
              {/* <div className="text-sm-end d-none d-sm-block">
                Design & Develop by Trio-S
              </div> */}
              <div className="text-sm-end d-none d-sm-block">
                <p className="mb-0 mt-1">
                  All rights reserved.© {new Date().getFullYear()}. Powered
                      by
                        <a onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
