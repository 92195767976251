import React, { Component} from "react"

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap"




// const [links, setLinks] = useState([]);
    
//       useEffect(() => {
//         quickLinksList();
//       }, []);
    
//       const quickLinksList = async () => {
//         try {
//           const response = await axios.post(url.BASE_URL + 'master/quickLinks');
//           if (response?.data?.body?.quickLinks) {
//             var filtered_links = response?.data?.body?.quickLinks.filter(e => e.gsql_stsid == 1)
//             setLinks(filtered_links);
//           } else {
//             toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
//           }
//         } catch (error) {
//           console.error("student details API Error:", error);
//         }
//       };

// const items = [
//   {
//     src: 'Some representative placeholder content for the first slide.',
//     altText: "Some representative placeholder content for the first slide.",
//     caption: "First slide label",
//   },
//   {
//     src: 'img5',
//     altText: "Some representative placeholder content for the first slide.",
//     caption: "Second slide label",

//   },
//   {
//     src: 'img4',
//     altText: "Some representative placeholder content for the first slide.",
//     caption: "Third slide label",
//   },
// ]

class SlideDark extends Component {
  constructor(props) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
    this.setState(props.Videolink)

  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === this.props.Videolink.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.Videolink.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    const slides = this.props.Videolink.map(item => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
            <div className="item">

             <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4 carousel-text"> 
                                  <a href={item.gsql_link} target="_blank" rel="noopener noreferrer" className="text-white font-weight-bold">{item.gsql_alttext}</a>
                                  
                                </p>
                              </div>
            </div>
   
        </CarouselItem>
      )
    })

    return (
      <React.Fragment>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          dark={true}
        >
          <CarouselIndicators
            items={this.props.Videolink}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
      </React.Fragment>
    )
  }
}

export default SlideDark
