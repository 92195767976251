import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "./ChartsDynamicColor";

const Pie = ({dataColors, examDetails}) => {

const piecolors = ["#34c38f", "#f1b44c", "#f04343", "#50a5f1"]
  const PieEChartColors = getChartColorsArray(dataColors);
  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      // data: ["Total Questions", "Wrong Answer", "Unanswered Questions", "Correct Answers"],
      data: [ "Correct Answers", "Wrong Answer", "Not Answered Questions"],
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: piecolors,
    series: [
      {
        name: "Total Answered",
        type: "pie",
        radius: "55%",
        center: ["50%", "60%"],
        data: [
          { value: examDetails[0]?.correctanswered, name: "Correct Answers" },
          { value: examDetails[0]?.unanswered, name: "Not Answered Questions" },
          { value: examDetails[0]?.wronganswer, name: "Wrong Answer" },
          // { value: 1548, name: "Total Questions" },
          // Number(examDetails[0]?.correctanswered !=0) &&  { value: examDetails[0]?.correctanswered, name: "Correct Answers" },
          // Number(examDetails[0]?.unanswered !=0) && { value: examDetails[0]?.unanswered, name: "Unanswered Questions" },
          // Number(examDetails[0]?.wronganswer !=0) &&  { value: examDetails[0]?.wronganswer, name: "Wrong Answer" },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};
export default Pie;