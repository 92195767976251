import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import {
    Button,
    Card,
    Col,
    Container,
    Input,
    Label,
    Row,
    Nav,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    NavItem,
    Badge,
    Collapse,
    CardBody,
    UncontrolledTooltip,
    CardHeader
} from "reactstrap";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import axios from "axios";
import * as url from "../../../src/helpers/url_helper";

//Import Breadcrumb
import BreadcrumbWithBack from "components/Common/BreadcrumbWithBack";

import classnames from "classnames";

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import images
import wechat from "../../assets/images/companies/wechat.svg";

import './smstyle.css'



//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import Spinners from "../../components/Common/Spinner";

import SMViewSidebar from './sm-view-sidebar';
import SMViewDetails from './sm-toolbar'

import './smstyle.css'



const StudyMaterialView = (location) => {
    const data = useLocation();
    const navigate = useNavigate();

    //meta title
    document.title = "StudyMaterialViews | ICE - Student Portal";
    const [isLoading, setLoading] = useState(false);
    const [topicName, setTopicName] = useState("");
    const [engContent, setEngContent] = useState('')
    const [datasets, setDatasets] = useState('')

    const [studentid, setStudentId] = useState("")
    const [studymaterialId, setStudyMaterialId] = useState("")
    const [sno, setSno] = useState("")
    const [studymatstatus, setStudyMatStatus] = useState("")
    const [topicid, setTopicId] = useState("")
    const [Examboardcat, setExamBoardCat] = useState("")
    const [exampaper, setExamPaper] = useState("")
    const [subjectname, setSubjectName] = useState("")
    const [exampaperid, setExamPaperId] = useState("")
    const [subjectId, setSubjectId] = useState("")
    const [ExamcatId, setExamCatid] = useState("")
    const [Examname, setExamName] = useState("")
    const [Subname, setSubName] = useState("")
    const [titlename, setTitleName] = useState("")
    const [modalOpen, setModalOpen] = useState(false);

    const [refferenceArray, setRefferenceArray] = useState([])


    const [subjectList, setSubjectList] = useState([]);



    const getMenuContent = (t, e) => {
        console.log('t', t)
        const params = {
            "sty_stid": Number(t.styid),
            // "st_stsid": studymatstatus,
            "stid": studentid,
            // "st_tid": Number(t.tid),
            // "st_exctid": Number(t.exctid),
            // "expid": Number(t.expid),
            "st_sid": Number(t.st_sid)
        }
        if (t?.examboardcategory) {
            setExamBoardCat(t?.examboardcategory)
            // setUserRoleId(data?.state?.clone_urid)
        }
        if (t?.exp_name) {
            setExamPaper(t?.exp_name)
        }
        if (t?.t_name) {
            setTopicName(t?.t_name)
        }
        if (t?.s_name) {
            setSubjectName(t?.s_name)
        } if (t?.st_title) {

            setTitleName(t?.st_title)
        }
        console.log('=--=--=-', params)
        listStudyMaterialDetails(params);
    }
    const toggleModal = (e) => {
        var data = e + '?page=hsn#toolbar=0'
        setDatasets(data)
        setModalOpen(!modalOpen)
    }
    const tog_fullscreen = () => {
        setModalOpen(false)
    }
    useEffect(() => {
        document.addEventListener("contextmenu", handlecontextmenu)

        const handleKeyDown = (event) => {

            if (event.code === "MetaLeft" && event.keyCode === 91 && event.metaKey === true) {
                event.preventDefault()
            }


            // Ctrl + A 
            if (event.ctrlKey && (event.key === 'a' || event.key === 'A')) {
                // Prevent default behavior for Ctrl+A (select all)
                event.preventDefault();
            }
            // Ctrl + c
            if (event.ctrlKey && (event.key === 'c' || event.key === 'C')) {
                // Prevent default behavior for Ctrl+C (copy)
                event.preventDefault();
            }
            // // Inspect disabled
            if (event.ctrlKey && event.shiftKey && (event.key == 'i' || event.key === 'I')) {
                event.preventDefault();
            }
            if ((event.ctrlKey || event.metaKey) && (event.keyCode === 91)) { // Ctrl + S or Command + S
                event.preventDefault();
                // alert('Screenshots are disabled.');
            }
            if (event.key === "Meta") {
                event.preventDefault();
                // alert('=-=-=')
            }
            if ((event.key === "PrintScreen" || event.key === 'Meta') && (event.keyCode === 44 || event.keyCode === 91)
                && event.metaKey === 'true') {
                event.preventDefault();
                // alert('=-=-=')
            }

        }
        window.addEventListener('keydown', handleKeyDown);
        // Add event listener to capture Ctrl+A and Ctrl+C keydown events
        document.addEventListener('keydown', handleKeyDown);

        // document.addEventListener('keyup', (e) => {
        // console.log('e=====>', e)
        //     if (e.key == 'PrintScreen') {
        //         navigator.clipboard.writeText('');
        //         alert('Screenshots disabled!');
        //     }
        //     if((e.metaKey) && (e.keyCode == 91)){
        //         navigator.clipboard.writeText('');

        //         alert('dis')
        //     }
        //     if (event.key === 's' && event.metaKey && event.shiftKey) {
        //         event.preventDefault(); // Prevent the default behavior
        //         console.log('Windows + Shift + S is restricted');
        //     }
        // });
        document.addEventListener('keydown', function (event) {
            // Check if the key combination is Windows + PrintScreen
            if (event.key === 'PrintScreen' && (event.metaKey || event.ctrlKey)) {
                event.preventDefault(); // Prevent the default behavior
                console.log('Windows + PrintScreen is restricted');
            }

            // Check if the key combination is Windows + Shift + S
            if (event.key === 's' && event.metaKey && event.shiftKey) {
                event.preventDefault(); // Prevent the default behavior
                console.log('Windows + Shift + S is restricted');
            }
        });

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            // document.removeEventListener("contextmenu",handlecontextmenu)
            document.removeEventListener('keydown', handleKeyDown);
        }


    }, [])
    const handleRightClick = (event) => {

        event.preventDefault(); // Prevent the default right-click behavior
        // You can add custom logic here if you want to handle the right-click event
    };
    // useEffect(() => {
    //     document.addEventListener("contextmenu", handlecontextmenu)

    //     const handleKeyDown = (event) => {
    //         console.log('event', event)

    //         if (event.code === "MetaLeft" && event.keyCode === 91 && event.metaKey === true) {
    //             console.log('===>')
    //             event.preventDefault()
    //         }


    //         // Ctrl + A 
    //         if (event.ctrlKey && (event.key === 'a' || event.key === 'A')) {
    //             // Prevent default behavior for Ctrl+A (select all)
    //             event.preventDefault();
    //         }
    //         // Ctrl + c
    //         if (event.ctrlKey && (event.key === 'c' || event.key === 'C')) {
    //             // Prevent default behavior for Ctrl+C (copy)
    //             event.preventDefault();
    //         }
    //         // // Inspect disabled
    //         if(event.ctrlKey && event.shiftKey && (event.key == 'i'  || event.key === 'I') ){
    //             event.preventDefault(); 
    //         }
    //         if ((event.ctrlKey || event.metaKey) && (event.keyCode === 91)) { // Ctrl + S or Command + S
    //             event.preventDefault();
    //             // alert('Screenshots are disabled.');
    //         }
    //         if (event.key === "Meta") {
    //             event.preventDefault();
    //             // alert('=-=-=')
    //         }
    //         if ((event.key === "PrintScreen" || event.key === 'Meta') && (event.keyCode === 44 || event.keyCode === 91)
    //             && event.metaKey === 'true') {
    //             event.preventDefault();
    //             // alert('=-=-=')
    //         }

    //     }
    //     window.addEventListener('keydown', handleKeyDown);
    //     // Add event listener to capture Ctrl+A and Ctrl+C keydown events
    //     document.addEventListener('keydown', handleKeyDown);

    //     // document.addEventListener('keyup', (e) => {
    //     // console.log('e=====>', e)
    //     //     if (e.key == 'PrintScreen') {
    //     //         navigator.clipboard.writeText('');
    //     //         alert('Screenshots disabled!');
    //     //     }
    //     //     if((e.metaKey) && (e.keyCode == 91)){
    //     //         navigator.clipboard.writeText('');

    //     //         alert('dis')
    //     //     }
    //     //     if (event.key === 's' && event.metaKey && event.shiftKey) {
    //     //         event.preventDefault(); // Prevent the default behavior
    //     //         console.log('Windows + Shift + S is restricted');
    //     //     }
    //     // });
    //     document.addEventListener('keydown', function (event) {
    //         console.log('---', event)
    //         // Check if the key combination is Windows + PrintScreen
    //         if (event.key === 'PrintScreen' && (event.metaKey || event.ctrlKey)) {
    //             event.preventDefault(); // Prevent the default behavior
    //             console.log('Windows + PrintScreen is restricted');
    //         }

    //         // Check if the key combination is Windows + Shift + S
    //         if (event.key === 's' && event.metaKey && event.shiftKey) {
    //             event.preventDefault(); // Prevent the default behavior
    //             console.log('Windows + Shift + S is restricted');
    //         }
    //     });

    //     return () => {
    //         window.removeEventListener('keydown', handleKeyDown);
    //         // document.removeEventListener("contextmenu",handlecontextmenu)
    //         document.removeEventListener('keydown', handleKeyDown);
    //     }


    // }, [])

    useEffect(() => {
        const data = localStorage.getItem('authUser')
        const parsevalue = JSON.parse(localStorage.getItem('authUser'))

        if (parsevalue?.UserInfo?.stu_interested[0]?.mstid != 1) {

            navigate('/dashboard')
        } else if (parsevalue?.UserInfo?.stu_interested[0]?.mstid == 1) {
        }
    }, [])


    useEffect(() => {

        const disableBackButton = (e) => {
            e.preventDefault(); // Prevent default behavior of back button
            window.history.forward(); // Move forward in history
        };
        // Push a new state to prevent going back
        window.history.pushState(null, "", window.location.href);

        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href)
        }
        // Add an event listener for the popstate event
        // window.addEventListener('popstate', disableBackButton);

        // Cleanup function to remove event listener
        // return () => {
        //   window.removeEventListener('popstate', disableBackButton);
        // };
    }, []);

    useEffect(() => {
        console.log('sttt', data?.state)


        if (data?.state) {
            // if (data?.state?.st_stsid) {
            //     setStudyMatStatus(data?.state?.st_stsid)
            //     // setUserRoleId(data?.state?.urid)
            // }
            if (data?.state?.sno) {
                setSno(data?.state?.sno)
                // setUserRoleId(data?.state?.urid)
            }
            // if (data?.state?.st_stsid) {
            //     setStudyMatStatus(data?.state?.st_stsid)
            //     // setUserRoleId(data?.state?.urid)
            // }
            // if (data?.state?.st_tid) {
            //     setTopicId(data?.state?.st_tid)
            //     // setUserRoleId(data?.state?.clone_urid)
            // }
            if (data?.state?.stid) {
                setStudentId(data?.state?.stid)
            }
            if (data?.state?.sty_stid) {
                setStudyMaterialId(data?.state?.sty_stid)
            }
            // if (data?.state?.examboardcategory) {
            //     setExamBoardCat(data?.state?.examboardcategory)
            //     // setUserRoleId(data?.state?.clone_urid)
            // }
            // if (data?.state?.exp_name) {
            //     setExamPaper(data?.state?.exp_name)
            // }
            // if (data?.state?.t_name) {
            //     setTopicName(data?.state?.t_name)
            // }
            // if (data?.state?.s_name) {
            //     setSubjectName(data?.state?.s_name)
            // }
            if (data?.state?.sid) {
                setSubjectId(data?.state?.sid)
            }
            if (data?.state?.st_exctid) {
                setExamCatid(data?.state?.st_exctid)
            }
            if (data?.state?.expid) {
                setExamPaperId(data?.state?.expid)
            }
            if (data?.state?.st_title) {
                setTitleName(data?.state?.st_title)
            }
        }
        else if (Examboardcat === "" && exampaper === "" && topicName === "" && subjectname === "" && titlename === "") {
            navigate('/studymaterials')
        }


    }, [data])
    useEffect(() => {
        const params = {
            "sty_stid": Number(studymaterialId),
            // "st_stsid": studymatstatus,
            "stid": studentid,
            // "st_tid": topicid,
            // "st_exctid": ExamcatId,
            "st_sid": subjectId || 0,
        }
        console.log('=-=-=', params)
        console.log(studymaterialId, 'stud')
        if (studymaterialId) {
            const params = {
                "sty_stid": Number(studymaterialId),
                // "st_stsid": studymatstatus,
                "stid": studentid,
                // "st_tid": topicid,
                // "st_exctid": ExamcatId,
                "st_sid": subjectId || 0,
            }
            console.log('params', params)
            listStudyMaterialDetails(params);
        }
    }, [studymaterialId])

    // const handlecontextmenu= (e) =>{
    //     console.log('e--> ', e)
    //     e.preventDefault()
    // }

    // const handleDragStart = (e) => {
    //     console.log('drag', e)
    //     e.preventDefault(); // Prevent default drag behavior
    //   };
    const handlecontextmenu = (e) => {
        e.preventDefault()
    }
    const onCloseClick = () => {
        setModalOpen(false)
    }
    const handleDragStart = (e) => {
        e.preventDefault(); // Prevent default drag behavior
    };


    const listStudyMaterialDetails = async (params) => {
        setLoading(true)
        var boardcatArr = [];
        const user = getLoggedInUser()

        const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

        try {
            const response = await axios.post(url.BASE_URL + 'master/getStudyMaterialsDetails', params, config);
            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response.data.body.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser();
                    } else {
                        await listStudyMaterialDetails();
                    }
                }
            }
            if (response?.data?.body?.StudyMaterialsDetails) {
                boardcatArr = response.data.body.StudyMaterialsDetails;
                setSubName(response?.data?.body?.StudyMaterialsDetails[0]?.s_name)
                setExamName(response?.data?.body?.StudyMaterialsDetails[0]?.exp_name)

                if (response?.data?.body?.StudyMaterialsDetails.length > 0) {

                    var data = response.data.body.StudyMaterialsDetails
                    var arr = [];
                    var filtered_subject_option = data.map(e => {
                        var extension = e.downloadablefile
                        var ext = extension?.split('.').pop();
                        var dataaaaa = {
                            "contentfiles": e.contentfiles,
                            "downloadablefile": e.downloadablefile,
                            "stf_description": e.stf_description,
                            "filetype": ext
                        }


                        arr.push(dataaaaa)
                    })
                    setRefferenceArray(arr)
                } else {

                    setRefferenceArray(response.data.body.StudyMaterialsDetails)
                }
                setSubjectList(response.data.body.StudyMaterialsTopics)


                await fetch(boardcatArr[0]?.contentfiles)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.text();
                    })
                    .then(html_content => {
                        setEngContent(html_content)

                        // if(content_uploads[i]?.stc_langflag == 1)
                        // {
                        //    setEngContent(html_content)
                        // }
                    })
                // bindMenuArray1(boardcatArr)

            } else {
                // toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("student detailsAPI Error:", error);
        } finally {

        }
    }

    return (
        <React.Fragment>
            <div className="page-content"
                onCopy={(event) => event.preventDefault()} onDragStart={handleDragStart} onKeyDown={(event) => {
                    event.preventDefault();
                }}
            >

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <BreadcrumbWithBack title={`${Examname}/${Subname}`}
                        breadcrumbItem={titlename}
                        navLink="/studymaterials"

                        state={{ subjectname: subjectname, Exampaper: exampaper, Examcatid: ExamcatId, subjectid: subjectId, exampaperid: exampaperid, topicid: topicid, studymaterialId: studymaterialId, sno: sno, examboardcategory: Examboardcat, s_name: subjectname, statusflag: 1 }}

                    />

                    {isLoading ?
                        <Spinners setLoading={setLoading} /> : <Row>


                            <Col
                                xl={(refferenceArray.length > 0 && refferenceArray[0].downloadablefile == null && refferenceArray[0].downloadablefile == undefined) && (subjectList && subjectList.length == 0) ? 12 : 9}
                            >
                                <Card>
                                    <CardBody dangerouslySetInnerHTML={{ __html: engContent }}>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={3}>
                                {
                                    refferenceArray && refferenceArray.length > 0 && refferenceArray[0].downloadablefile != null && refferenceArray[0].downloadablefile != undefined ?
                                        <Card >
                                            <div className="px-2 pt-2 pb-1">
                                                <h5 className="mb-1">References</h5>
                                            </div>
                                            <CardBody className="p-3 referencecard">
                                                {
                                                    (refferenceArray || []).map((item, index) => (
                                                        <Row key={index}>
                                                            <Col lg="1">
                                                                {item && item.filetype != null && item.filetype != "" && item.filetype != undefined && item.filetype == "pdf" ? <i className="me-3 mx-lg-auto mb-3 mt-1  rounded-circle mdi mdi-file-pdf-box font-size-13 pdfcolor" /> :
                                                                    item && item.filetype != null && item.filetype != "" && item.filetype != undefined ?

                                                                        <i className="me-3 mx-lg-auto mb-3 mt-1  rounded-circle  bx bx-image-alt font-size-13 imagecolor" /> : ""}
                                                            </Col>

                                                            <Col lg="10">
                                                                <div>

                                                                    <a
                                                                        onClick={() => {
                                                                            toggleModal(item.downloadablefile)
                                                                        }}
                                                                        // ={item.downloadablefile}
                                                                        // target="_blank"
                                                                        className="d-block text-black text-decoration-underline mb-2 font-size-13 "

                                                                    >
                                                                        {item.stf_description}
                                                                    </a>


                                                                    <Modal
                                                                        size="xl"
                                                                        isOpen={modalOpen}
                                                                        toggle={() => {
                                                                            tog_fullscreen();
                                                                        }}
                                                                        className="modal-fullscreen"
                                                                    >
                                                                        <div className="modal-header">
                                                                            <h5
                                                                                className="modal-title mt-0"
                                                                                id="exampleModalFullscreenLabel"
                                                                            >
                                                                                StudyMaterials Modal
                                                                            </h5>
                                                                            <button
                                                                                onClick={() => {
                                                                                    setModalOpen(false);
                                                                                }}
                                                                                type="button"
                                                                                className="close"
                                                                                data-dismiss="modal"
                                                                                aria-label="Close"
                                                                            >
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className="modal-body">

                                                                            <iframe className="iframeSize1"
                                                                                onContextMenu={handleRightClick}
                                                                                src={datasets}
                                                                                // allow='autoplay; encrypted-media'
                                                                                width="100%"
                                                                                height="100%"
                                                                            // allowfullscreen
                                                                            // title='video'
                                                                            />

                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    tog_fullscreen();
                                                                                }}
                                                                                className="btn btn-secondary "
                                                                                data-dismiss="modal"
                                                                            >
                                                                                Close
                                                                            </button>

                                                                        </div>
                                                                    </Modal>




                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ))
                                                }
                                            </CardBody>
                                        </Card> : ""
                                }
                                {
                                    subjectList && subjectList.length > 0 ?

                                        <Card className="">
                                            <div className="px-2 pt-2 pb-1 ">
                                                <h5 className="mb-1">Other Titles</h5>
                                            </div>
                                            <CardBody className="p-2 mt-10 referencecard">

                                                <div className="card border-0 shadow-none mb-0">
                                                    <ul className="list-unstyled mb-0">
                                                        {
                                                            (subjectList || [])?.map((t, index) => (

                                                                <li key={index} onClick={() => {
                                                                    getMenuContent(t, index)
                                                                }}>
                                                                    <Link to="#" className="d-flex align-items-center font-size-11 sub_li othertopics">
                                                                        <span className="me-auto">{t.st_title}</span>
                                                                        {t.t_count && <span className="badge bg-success rounded-pill p-1 ms-2">{t.t_count}</span>}
                                                                    </Link>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </CardBody>
                                        </Card> : ""

                                }

                            </Col>

                            {/* <SMViewSidebar /> */}
                        </Row>

                    }
                </Container>
            </div>

        </React.Fragment>
    );
};


export default withRouter(StudyMaterialView);
