import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import '../../assets/customstyle.css';

// import {
//   Button,
//   Card,
//   Col,

//   Row,
//   Nav,

//   NavItem,

// } from "reactstrap";
import {
    Table,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Button,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import layout2 from "../../assets/images/nodatafound.jpg";

//Import Breadcrumb
import EBBreadcrumbs from "../../components/Common/EBBreadcrumbs";

import classnames from "classnames";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper";
import axios from "axios";
import * as url from "../../../src/helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Paginations from '../../components/Common/Pagination';
import Spinners from "../../components/Common/Spinner";

import './full.css'
const ModelExam = () => {
    //meta title
    document.title = "StudyMaterials | ICE - Student Portal";
    const [isLoading, setLoading] = useState(false);
    const [boardCategoryList, setBoardCategoryList] = useState([])
    const [boardCategoryTopicList, setBoardCategoryTopicList] = useState([])
    const [details, setdetails] = useState([])
    const [cid, setcid] = useState([])
    const user = getLoggedInUser();
    const navigate = useNavigate();
    // pagination
    // const [jobGridData, setJobGridData] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const perPageData = 8;
    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;
    const currentData = useMemo(() => boardCategoryList?.slice(indexOfFirst, indexOfLast), [boardCategoryList, indexOfFirst, indexOfLast])

    // useEffect(() => {
    //     setJobGridData(currentData);
    // }, [currentData]);

    const colorArray = [
        { "badge_color": "text-bg-info", "bg_color": "btn-soft-info" },
        { "badge_color": "text-bg-warning", "bg_color": "btn-soft-warning" },
        { "badge_color": "text-bg-success", "bg_color": "btn-soft-success" },
        { "badge_color": "text-bg-secondary", "bg_color": "btn-soft-secondary" },
        { "badge_color": "text-bg-primary", "bg_color": "btn-soft-primary" },
        { "badge_color": "text-bg-dark", "bg_color": "btn-soft-dark" },
    ]



    useEffect(() => {
        const data = localStorage.getItem('authUser')
        const parsevalue = JSON.parse(localStorage.getItem('authUser'))


        if(parsevalue?.UserInfo?.stu_interested[0]?.mstid == 2){
            examModelList();
            
          }else if(parsevalue?.UserInfo?.stu_interested[0]?.mstid == 1 && parsevalue?.UserInfo?.stu_interested[1]?.mstid == 2){
            examModelList();
      
          }else if(parsevalue?.UserInfo?.stu_interested[1]?.mstid == undefined || parsevalue?.UserInfo?.stu_interested[0]?.mstid != 2){
      
            navigate('/dashboard')
          }

   
    }, []);

    const examModelList = async () => {

        const data = {
            stid: Number(user.UserInfo.stid),


        };
        const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

        try {
            const response = await axios.post(url.BASE_URL + 'master/modelexamList', data, config);
            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response.data.body.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser();
                    } else {
                        await examModelList();
                    }
                }
            }

            if (response?.data?.body?.modelexamList) {
                setdetails(response.data.body.modelexamList);
                setcid(response.data.body.modelexamList[0].cid)
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("student details API Error:", error);
        }
    };

    const [activeTab, setActiveTab] = useState(0);
    const [modal, setModal] = useState(false);
    const [badgeLength, setBadgeLength] = useState();
    const [isOpen, setIsOpen] = useState(true)
    const [activeGroupName, setActiveGroupName] = useState("");
    const [activeSubjectName, setActiveSubjectName] = useState("");
    const [activeSubjectTab, setActiveSubjectTab] = useState("1");
    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (boardCategoryList && boardCategoryList.length > 0) {
            setActiveTab(0)
            setActiveGroupName(boardCategoryList[0].ex_name.concat("-").concat(boardCategoryList[0].exct_name));
        }
    }, [boardCategoryList]);

    useEffect(() => {
        if (boardCategoryTopicList && boardCategoryTopicList.length > 0) {
            setActiveSubjectTab(0)
            setActiveSubjectName(boardCategoryTopicList[0].topicname);
        }
    }, [boardCategoryTopicList]);


    const filterTopics = ({ category }) => {
        // if (displayCategory === "inbox" && category === "trash") {
        //   return false;
        // }
        // return (
        //   (displayCategory === "inbox" || displayCategory === category)
        // );
        return
    }
    const getMenuContent = (item, index) => {
        setActiveTab(index)
        setActiveSubjectTab(index)
        setActiveGroupName(item.ex_name.concat("-").concat(item.exct_name));
        setActiveSubjectName(item.topicname);
    }

    const toggleVertical = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const handleClick = (item) => {
        if (item.subjectid === 3) {
            navigate("/Instructionspage", { state: { id: item.cid, type: 2 } });
        } else {
            navigate("/Instructionspage", { state: { id: item.cid, type: 2 } });
        }
    };
    const reattempt = (item) => {

if(item){
    navigate("/onlinetest", { state: { id: item.testid, type: 2, reattempt: 1, cid: item.cid } });

}else{
    navigate("/dashboard")
  }
    };
    const resume = (item) => {
if(item){


    navigate("/onlinetest", { state: { id: item.testid, type: 2, resume: 1, cid: item.cid } });
}else{
    navigate("/dashboard")
  }
    };

    const review = (item) => {

        navigate('/reviewtest', { state: { id: Number(item.testid) } })
    };

    return (
        <React.Fragment>


            <Row>

                <Col lg={12} className="mainpage1">

                    <CardBody>
                        {isLoading ?
                            <Spinners setLoading={setLoading} /> :
                            <Row>

                                <Col md="12">
                                    <TabContent

                                        className="text-muted mt-4 mt-md-0"
                                    >

                                        <TabPane>
                                            {details && details.length > 0 ?

                                                <Row>
                                                    {(details || []).map((item, key) => (
                                                        <Col xl={3} md={6} key={key}>
                                                            <Card>
                                                                <CardBody>

                                                                    <Row>
                                                                        <Col lg="11">

                                                                            <h5 className="fs-17 mb-2">
                                                                                <div className="text-dark">{item.c_name}</div> </h5>
                                                                        </Col>
                                                                        <Col lg="1">
                                                                            {item?.testid != null && item?.test_completed == 1 && <Col lg="1">
                                                                                <div className="reviebutton2">
                                                                                    <Button type="button" color="primary" className="revbutt" onClick={() => { review(item) }}>
                                                                                        <i className="dripicons-preview font-size-15 align-middle me-1"></i>
                                                                                        {/* <i className="mdi mdi-file-find font-size-15 align-middle me-2"></i> */}
                                                                                        {" "}

                                                                                    </Button>

                                                                                </div>
                                                                            </Col>}
                                                                        </Col>
                                                                    </Row>


                                                                    {/* <h5 className="fs-17 mb-2">
                                                                        <Link to="/Instructionspage" className="text-dark">{item.c_name}</Link> </h5> */}
                                                                    <ul className="list-inline mb-0">

                                                                        <li className="list-inline-item" style={{ width: '100%' }}>
                                                                            <Row>
                                                                                <Col lg="9">
                                                                                    <p className="text-muted fs-14 mb-0"><i className="mdi mdi-clock-time-four-outline"></i> {item.c_duration}</p>
                                                                                </Col>
                                                                                {/* {item?.testid != null && item?.test_completed == 1 && <Col lg="3">
                                                                                    <div className="reviebutton">
                                                                                        <Button type="button" color="primary" onClick={() => { review(item) }}>
                                                                                            <i className="dripicons-preview font-size-15 align-middle me-2"></i>
                                                                                            
                                                                                            {" "}
                                                                                            Review
                                                                                        </Button>

                                                                                    </div>
                                                                                 
                                                                                </Col>} */}

                                                                            </Row>
                                                                        </li>

                                                                    </ul>
                                                                    <hr className="line3" />
                                                                    <div className="mt-3 hstack gap-2">

                                                                        <div className="col scorecolval">

                                                                            <div>


                                                                                <p className="text-muted mb-0 scorealign"><i className="fas fa-layer-group iconspace"></i>

                                                                                    Max Score

                                                                                </p>
                                                                                <p className="text-muted mb-0 scores">

                                                                                    <p className='cardnumber1'>
                                                                                        {item.c_totalmarks} </p>   </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col quescolval">

                                                                            <div>


                                                                                <p className="text-muted mb-0 quesalign"><i className="dripicons-question iconspace"></i>
                                                                                    Questions




                                                                                </p>

                                                                                <p className="text-muted mb-0 scores">

                                                                                    <p className='cardnumber1'>
                                                                                        {item.c_totalquestions}  </p>   </p>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="col durationcolval">
                                                                    <div className="text-right rightalign">
                                                                        <p className="text-muted mb-0 duralign"><i className="dripicons-question iconspace"></i>Mark/Question</p>
                                                                        <p className="text-muted mb-0 scores">

                                                                            <p className='cardnumber1'>
                                                                                {item.c_totalmarks}</p>   </p>

                                                                    </div>

                                                                </div> */}


                                                                    </div>
                                                                    <div>
                                                                        {item?.testid != null && item?.test_completed == 1 ? (
                                                                            <div
                                                                            className={item?.isnewtest == 0  ? "btnModalDesign1" : "btnModalDesign"}

                                                                            //  className="btnModalDesign"
                                                                             ><button onClick={() => reattempt(item)} 
                                                                             className=
                                        
                                                                             {item?.isnewtest == 0  ? "btn btn-soft-success w-100" : 
                                                                             "btn btn-soft-success w-50"
                                                                              }
                                                                            //  className="btn btn-soft-success w-50"
                                                                             >
                                                                                Reattempt
                                                                            </button>
                                                                            {item?.isnewtest == 1 ?
                                                                                <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-50">
                                                                                    New test
                                                                                </button>
                                                                                : "" }
                                                                                
                                                                                </div>
                                                                        ) : null}

                                                                        {item?.testid != null && item?.isresumed == 1 ? (



                                                                            <div className="btnModalDesign">   <button onClick={() => resume(item)} 
                                                                            className={item?.isnewtest == 0  ? "btn btn-soft-danger w-100" : 
                                                                            "btn btn-soft-danger w-50"
                                                                             }
                                                                            // className="btn btn-soft-danger w-50"
                                                                            >
                                                                                Resume
                                                                            </button>
                                                                            
                                        {
item?.isnewtest == 1 ?
                                                                                <button onClick={() => handleClick(item)} className="btn btn-soft-primary w-50">
                                                                                    New test
                                                                                </button> : "" }</div>


                                                                        ) : null}

                                                                        {item.testid == null ? (
                                                                            <button onClick={() => handleClick(item)} 
                                                                            className="btn btn-soft-primary w-100"
                                                                            >
                                                                                Start Test
                                                                            </button>
                                                                        ) : null}
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                :
                                                <div className="imgalignno">

                                                    <img src={layout2} alt="Girl in a jacket" width="450" height="450"></img>
                                                </div>
                                            }
                                            {/* <Paginations
                                                perPageData={perPageData}
                                                data={boardCategoryTopicList}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                isShowingPageLength={true}
                                                paginationDiv="col-sm-12 col-md-7"
                                                paginationClass="pagination justify-content-end pagination-rounded"
                                            /> */}
                                        </TabPane>

                                    </TabContent>
                                </Col>
                            </Row>
                        }
                    </CardBody>

                </Col>

            </Row>

        </React.Fragment >
    );
};

export default withRouter(ModelExam);
