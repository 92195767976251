import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as url from "../../../src/helpers/url_helper";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import * as constants from "../../constants/layout";
import { logoutUser } from "store/auth/login/actions";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ChangePasswordModal = ({ isOpen, toggle }) => {

    const [isLoading, setLoading] = useState(false);
    const [oldpasswordShow, setOldPasswordShow] = useState(false);
    const [newpasswordShow, setNewPasswordShow] = useState(false);
    const [confmpasswordShow, setConfmPasswordShow] = useState(false);
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required("Old Password is required"),
            newPassword: Yup.string()
                .required("New Password is required")
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
                    "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
                ),
            confirmNewPassword: Yup.string()
                .required("Confirm New Password is required")
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
                    "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
                ),
            // confirmNewPassword: Yup.string()
            //     .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            //     .required("Confirm New Password is required")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            const user = getLoggedInUser()

            const data = {
                stid: Number(user.UserInfo.stid),
                old_password: values.oldPassword,
                new_password: values.newPassword
            };

            const config = { headers: { authorization: 'Bearer '.concat(user.UserInfo.accessToken) } };

            try {
                const response = await axios.post(url.BASE_URL + 'user/changePassword', data, config);
                if (response && response?.data?.body?.statusFlag === 3) {
                    toast.error(response.data.body.message, { autoClose: 2000 });
                    localStorage.removeItem("authUser");
                    window.location.reload();
                    return;
                }

                if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                    const refresh = await getRefreshToken();

                    if (refresh) {
                        if (Number(refresh) === constants.invalidRefreshToken) {
                            logoutUser();
                        } else {
                            await onSubmit();
                        }
                    }
                }

                if (response?.data?.body?.statusFlag === 1) {
                    toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                    setTimeout(() => {

                        localStorage.removeItem("authUser");
                        window.location.reload()
                    }, 2000)
                } else {
                    toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                }
            } catch (error) {
                // Handle error
                console.error("Change Password API Error:", error);
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        }
    });
    useEffect(() => {
        if (isOpen) {
            validation.resetForm()
        }
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle}>Change Password</ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <Label className="form-label">
                        Old Password <span className="required">*</span>
                    </Label>
                    <div className="input-group auth-pass-inputgroup">
                        <Input
                            name="oldPassword"
                            className="form-control"
                            placeholder="Old Password"
                            // type="password"
                            maxLength={constants.textboxLength.u_password}

                            type={oldpasswordShow ? "text" : "password"}

                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.oldPassword || ""}
                            invalid={
                                validation.touched.oldPassword && validation.errors.oldPassword ? true : false
                            }
                        />
                        <button onClick={() => setOldPasswordShow(!oldpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                            <i className="mdi mdi-eye-outline"></i>
                        </button>
                        {validation.touched.oldPassword && validation.errors.oldPassword ? (
                            <FormFeedback type="invalid">{validation.errors.oldPassword}</FormFeedback>
                        ) : null}
                    </div>
                </div>

                <div className="mb-3">
                    <Label className="form-label">New Password <span className="required">*</span></Label>
                    <div className="input-group auth-pass-inputgroup">

                        <Input
                            name="newPassword"
                            className="form-control"
                            placeholder="New Password"
                            maxLength={constants.textboxLength.u_password}
                            // type="password"
                            type={newpasswordShow ? "text" : "password"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.newPassword || ""}
                            invalid={
                                validation.touched.newPassword && validation.errors.newPassword ? true : false
                            }
                        />

                        <button onClick={() => setNewPasswordShow(!newpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                            <i className="mdi mdi-eye-outline"></i>
                        </button>
                        {validation.touched.newPassword && validation.errors.newPassword ? (
                            <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                        ) : null}
                    </div>
                </div>

                <div className="mb-3">
                    <Label className="form-label">Confirm New Password <span className="required">*</span></Label>
                    <div className="input-group auth-pass-inputgroup">
                        <Input
                            name="confirmNewPassword"
                            className="form-control"
                            placeholder="Confirm New Password"
                            // type="password"
                            maxLength={constants.textboxLength.u_password}
                            type={confmpasswordShow ? "text" : "password"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmNewPassword || ""}
                            invalid={
                                validation.touched.confirmNewPassword && validation.errors.confirmNewPassword ? true : false
                            }
                        />
                        <button onClick={() => setConfmPasswordShow(!confmpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                            <i className="mdi mdi-eye-outline"></i>
                        </button>
                        {validation.touched.confirmNewPassword && validation.errors.confirmNewPassword ? (
                            <FormFeedback type="invalid">{validation.errors.confirmNewPassword}</FormFeedback>
                        ) : null}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={validation.handleSubmit}>Update</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Modal>

    );

};

export default ChangePasswordModal;