import PropTypes from "prop-types";
import React from "react";
import { Container, Row, Col } from "reactstrap"; 

import Breadcrumb from "../../components/Common/Breadcrumb";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import Subjectcontent from "./FullLength";

const Subject = props => {
  //meta title
  document.title = "Topic Wise Mock Test | Skote - React Admin & StudyMaterials Template";

  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
      <Breadcrumb  breadcrumbItem="Model Exam" />
             <Row>
         
          <Col lg='12'>
            <Subjectcontent />
            </Col>        
                 
        </Row>
      </Container>
    </div>
  </React.Fragment>
  );
};

Subject.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Subject);
