import PropTypes from "prop-types";
import React from "react";
import { Container, Row, Col } from "reactstrap"; 
import WelcomeComp from "./WelcomeComp";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

const Dashboard = props => {
  //meta title
  document.title = "Dashboard | ICE - Student Portal";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumb
            Item={props.t("Dashboard")}
          /> */}
               <Row>
            <Col xl="12" >
            {/* style={{height: '400px'}} */}
            <WelcomeComp />
            </Col>                    
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
