import PropTypes from "prop-types";
import React from "react";
import { Container, Row, Col } from "reactstrap"; 
import Topicwise from "./TopicWiseSub";


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";


//i18n
import { withTranslation } from "react-i18next";
import Topiccontent from "./TopicWise";

const Topic = props => {
  //meta title
  document.title = "Topic Wise Mock Test | Skote - React Admin & StudyMaterials Template";

  return (
    <React.Fragment>
    <div className="page-content pt-3">
      <Container fluid>
      <Breadcrumb breadcrumbItem="Topic Wise MockUp Test" />
       
             <Row>
          {/* <Col lg='2'>
        
          <Topicwise />

          </Col>   */}
          <Col lg='12'>
            <Topiccontent />
            </Col>        
                 
        </Row>
      </Container>
    </div>
  </React.Fragment>
  );
};

Topic.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Topic);
