import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Button, Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
    return (
        <Row>
            <Col xs={12}>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">

                        <Link to={props.navLink} state={props.state}>
                            <i className="bx bx-arrow-back font-size-16 align-middle me-2 "></i>{" "}  
                        </Link>

                        {props.breadcrumbItem}</h4>
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <BreadcrumbItem><Link to={props.navLink}>{props.title}</Link></BreadcrumbItem>
                            <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
                        </ol>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

Breadcrumb.propTypes = {
    breadcrumbItem: PropTypes.string,
    title: PropTypes.string
}

export default Breadcrumb
