import React, { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row, Button,
  CardHeader
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import images
import profile from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import axios from "axios";
import * as url from "../../../src/helpers/url_helper";
import RedirectScreen from "./redirectscreen";
//redux
import { useSelector, useDispatch } from "react-redux";
const ConfirmPassword = () => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //meta title
  document.title="Recover Password | Skote - Vite React Admin & Dashboard Template";
  const [isLoading, setIsLoading] = useState(false)

  const { emailid } = useParams()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("New Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
      ),
      confirmNewPassword:  Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required("Confirm New Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
      )
    }),
    onSubmit: (values) => {
    }
  });


  const UpdatePassword = async () => {
    validation.handleSubmit();
    if (validation.values.newPassword === "" || validation.values.newPassword === undefined || validation.values.newPassword === null || !(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/.test(validation.values.newPassword))) {
      return false;
    }
    if (validation.values.confirmNewPassword === "" || validation.values.confirmNewPassword === undefined || validation.values.confirmNewPassword === null || !(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/.test(validation.values.confirmNewPassword))) {
      return false;
    }
    if (validation.values.newPassword !== validation.values.confirmNewPassword) {
      return false;
    }
    setIsLoading(true)
    try {
      const data = {
        emailid: emailid,
        new_password: validation.values.newPassword
      }
      const response = await axios.post(url.BASE_URL + 'user/resetPassword', data);
      if (response.data.body.statusFlag === 2) {
        toast.error(response?.data?.body?.messages, { position: toast.POSITION.TOP_RIGHT });

        // setEmailNotValid(response.data.body.message)
        setIsLoading(false)
        return
      } else {
        navigate('/resetpassword')
      }

    } catch (error) {
      setIsLoading(false)
      console.error('Error emaild check:', error);
    }
  }
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Reset Password</h5>                        
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img
                        src={profile}
                        alt=""
                        className="img-fluid"
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                      

                  <div className="p-2">

                    <Form className="form-horizontal"
                      onSubmit={(e) => {
                        // e.preventDefault();
                        // validation.handleSubmit();
                        // return false;
                      }}
                    >
                      <div className="mb-3">
                    <Label className="form-label">New Password <span className="required">*</span></Label>
                    <Input
                        name="newPassword"
                        className="form-control"
                        placeholder="New Password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.newPassword || ""}
                        invalid={
                            validation.touched.newPassword && validation.errors.newPassword ? true : false
                        }
                    />
                    {validation.touched.newPassword && validation.errors.newPassword ? (
                        <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                    ) : null}
                </div>

                <div className="mb-3">
                    <Label className="form-label">Confirm New Password <span className="required">*</span></Label>
                    <Input
                        name="confirmNewPassword"
                        className="form-control"
                        placeholder="Confirm New Password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confirmNewPassword || ""}
                        invalid={
                            validation.touched.confirmNewPassword && validation.errors.confirmNewPassword ? true : false
                        }
                    />
                    {validation.touched.confirmNewPassword && validation.errors.confirmNewPassword ? (
                        <FormFeedback type="invalid">{validation.errors.confirmNewPassword}</FormFeedback>
                    ) : null}
                </div>
                      <div className="text-end">
                        {/* <button
                          className="btn btn-primary w-md "
                          type="submit"
                        >
                          Reset
                        </button> */}

                        {isLoading ? <Button type="button" color="primary" disabled>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                        Updating
                                      </Button> : <Button type="button" color="primary" 
                                        onClick={() => {
                                          UpdatePassword();                                        
                                        }}
                                      >
                                      Reset
                                      </Button>}
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link
                    to="/login"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    Sign In here
                  </Link>{" "}
                </p>
                <p className="mb-0 mt-1">
                    All rights reserved.© {new Date().getFullYear()}. Powered
                        by
                        <a onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                  </p>
              </div>
            </Col>
          </Row>
          <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConfirmPassword;
