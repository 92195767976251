import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import '../../assets/customstyle.css'

const EBBreadcrumbs = props => {
    return (
        <Row>
            <Col xs={12}>
               
                <div className="d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18 pt-3 pb-3">{props.breadcrumbTitle}</h4>
                {
                    props.breadcrumPaper != "" && props.breadcrumbItem != "" && props.title != "" ? 
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <BreadcrumbItem><Link to="#">{props.title}</Link></BreadcrumbItem>
                            <BreadcrumbItem><Link to="#">{props.breadcrumPaper}</Link></BreadcrumbItem>
                            <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
                        </ol>
                
                    </div> :""}
                </div> 
            </Col>
        </Row>
    )
}

EBBreadcrumbs.propTypes = {
    breadcrumbItem: PropTypes.string,
    title: PropTypes.string
}

export default EBBreadcrumbs
