import React from "react"
import { Container, Row, Col } from "reactstrap"
import '../../assets/customstyle.css'
const Footer = () => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  return (
    <React.Fragment>
      <footer className="footercss">
        <Container fluid={true}>
          <Row>
            {/* <Col md={6}>{new Date().getFullYear()} © Trio-S.</Col> */}
            <Col md={6}></Col>
            {/* className="justify-content-end" */}
            <Col md={6} >
              <div className="text-sm-end d-none d-sm-block">               
              <p className="mb-0 mt-1">
              All rights reserved.© {new Date().getFullYear()}. Powered
                  by
                        <a onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                </p>
              </div>
              {/* <div className="mt-4 mt-md-5 text-sm-end">
                      <p className="mb-0">
                        © {new Date().getFullYear()}. Powered
                        by
                        <a onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                      </p>
                    </div> */}
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
