import React, { useEffect, useState } from "react";
import {
  Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, NavItem,
  NavLink,
  TabContent,
  TabPane, Button
} from "reactstrap";
import './auth.scss'

import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
// action
import { registerUser, apiError } from "../../store/actions";
import * as url from "../../../src/helpers/url_helper";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import classnames from "classnames"
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import images
//import profileImg from "../../assets/images/profile-img.png";
import profileImg from "../../assets/images/vision.jpg";
import logoImg from "../../assets/images/logo.svg";
import logolight from "../../assets/images/trios-logo.jpg";
import { tagsData } from "components/Common/blog";
import Spinners from "../../components/Common/Spinner";

import {REGISTRATIONVARIABLE} from '../../constants/commonVariable'
const Register = props => {
  const [categories, setCategories] = useState([]);
  const [Topic, setTopic] = useState([]);

  const [activeTab, setactiveTab] = useState(1)
  //const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  //const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const [emailNotValid, setEmailNotValid] = useState("")


  const [selectedLanguage, setselectedLanguage] = useState([])

  const [languageList, setLanguageList] = useState([])
  const [isLoading, setLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  //meta title
  document.title = "Register | ICE - Student Portal";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      mobileno: '',
      password: '',
      selectedLanguage: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email")
      .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,"Invalid email."),
      username: Yup.string().required("Please Enter Your Username"),
      mobileno: Yup.string()
        .required("Please Enter Your Mobile No.")
        .matches(/^\d{10}$/, "Mobile No. must be exactly 10 digits and contain only numbers"),
      password: Yup.string()
        .required("Please Enter Your Password")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
          "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
        ),
        selectedLanguage: Yup.object().required("Please Select Language")
    }),
    onSubmit: (values) => {


    }
  });

  const checkhandlePerference = (row) => {
    if (categories && categories.length > 0) {
      const categoriesList_arr = Object.assign([], categories)
      const result = []
      categoriesList_arr.forEach(i => {
          const obj = { ...i }
          if (row.exctid === obj.exctid) {
              if (obj.checked === true) {
                  obj['checked'] = false
              } else if (obj.checked === false) {
                  obj['checked'] = true
              } else {
                  obj['checked'] = obj['checked']
              }
          }
          result.push(obj)
          setCategories(result)
      })
    }
  };

  const checkhandleTopic = (row) => {
    if (Topic && Topic.length > 0) {
      const TopicList_arr = Object.assign([], Topic)
      const result = []
      TopicList_arr.forEach(i => {
          const obj = { ...i }
          if (row.mstid === obj.mstid) {
              if (obj.checked === true) {
                  obj['checked'] = false
              } else if (obj.checked === false) {
                  obj['checked'] = true
              } else {
                  obj['checked'] = obj['checked']
              }
          }
          result.push(obj)
          setTopic(result)
      })
    }
  };

  useEffect(() => { 
    fetchData();
  }, []);

  const fetchData = async () => {
    try {

      const response = await axios.post(url.BASE_URL + 'master/getexboard_exboardcategoryList');
      const result = []
      response.data.body.exboardexcategorylist.forEach(i => {
          const obj = { ...i }
          obj['checked'] = false
          result.push(obj)
      })

      const topicResult = []
      response.data.body.topiclist.forEach(i => {
          const obj = { ...i }
          obj['checked'] = false
          topicResult.push(obj)
      })
      setLanguageList(response.data.body.languageList)
      setCategories(result);
      setTopic(topicResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

 

  async function toggleTab(tab) {  
    if (activeTab === 1) {
      validation.handleSubmit();
      if (validation.values.username === "" || validation.values.username === undefined || validation.values.username === null) {
        return false;
      }
      if (validation.values.email === "" ||  validation.values.email === undefined || validation.values.email === null || !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(validation.values.email))) {
        return false;
      }
      if (validation.values.mobileno === "" ||  validation.values.mobileno === undefined || validation.values.mobileno === null ||  !(/^\d{10}$/.test(validation.values.mobileno))) {
        return false;
      }
      if (validation.values.password === "" ||  validation.values.password === undefined || validation.values.password === null || !(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/.test(validation.values.password))) {
        return false;
      }

      if (validation.values.selectedLanguage === "" ||  validation.values.selectedLanguage === undefined || validation.values.selectedLanguage === null) {
        return false;
      }

      // setLoading(true)
      // try {       
      //   const data = {
      //     emailid: validation.values.email
      //   }
      //   const response = await axios.post(url.BASE_URL + 'master/checkEmailIsValid', data);        
      //   if (response.data.body.statusFlag === 2) {
      //     setEmailNotValid(response.data.body.message)
      //     // setLoading(false)
      //     return
      //   } else {
      //     setEmailNotValid("")
      //     // setLoading(false)
      //   }
        
      // } catch (error) {
      //   // setLoading(false)
      //   console.error('Error emaild check:', error);
      // }
    } else if (activeTab === 2) {
       if (tab === 3) {
        var categoryChecked = categories.filter((e) => e.checked === true)
        if(categoryChecked.length === 0){
          toast.error(REGISTRATIONVARIABLE.PREFERENCEVALIDATE, { position: toast.POSITION.TOP_RIGHT });
          return
        }
      }
    }

    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
      setEmailNotValid("")
    }
   
  }

  const AccountProperties = createSelector(
    (state) => state.Account,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success
      // loading: account.loading,
    })
  );

  const {
    user,
    registrationError, success
    // loading
  } = useSelector(AccountProperties);

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  useEffect(() => {
    success && setTimeout(() => navigate("/login"), 2000)
  }, [success])

  const numberOnly = (event) => {
    const regex = /^[0-9]*$/
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (!regex.test(key)) {
        event.preventDefault()
        return false
    }
    return true
}
const alphOnly = (event) => {
  const regex = /^[a-zA-Z\s]*$/;
  const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
  if (regex.test(key) || key === '') {
    return true
  }
  event.preventDefault()
  return false
}
const checkMobileEmail = async () => {
  try{
    // setLoading(true)
    const data = {
      email: validation.values.email,
      mobileno : Number(validation.values.mobileno)
    }
    const response = await axios.post(url.BASE_URL + 'user/checkmobileno', data);
          if (response.data.body.statusFlag === 1) {
            // toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            toggleTab(activeTab + 1);
          } else {
            toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            // toast.error(response.data.body.message, { autoClose: 2000 });
          }
  }catch {
    // con
  }
}


  const handleSave = async () => {
    try {
      setLoading(true)
      var topicChecked = Topic.filter((e) => e.checked === true)
      if(topicChecked.length === 0){
        toast.error(REGISTRATIONVARIABLE.LOOKINGFORVALIDATE, { position: toast.POSITION.TOP_RIGHT });
        return
      }  
      var categoryChecked = categories.filter((e) => e.checked === true)
      if(categoryChecked.length === 0){
        toast.error(REGISTRATIONVARIABLE.PREFERENCEVALIDATE, { position: toast.POSITION.TOP_RIGHT });
        return
      }   
      if( validation.values.username == "" || selectedLanguage.value == "" || 
      validation.values.email == "" || validation.values.mobileno == "" 
    || validation.values.password == ""
    ){

      toast.error('Please enter all mandatory field!', { position: toast.POSITION.TOP_RIGHT });
    } 
      const st_whatsappenabled = document.getElementById('customSwitchsizesm').checked ? 1 : 0;
      const st_categories = categories.filter((e)=> e.checked === true)
      const st_topic = Topic.filter((e)=> e.checked === true)
      const data = {
        process_type: "save",
        st_name: validation.values.username,
        st_lid: selectedLanguage.value,
        st_email: validation.values.email,
        st_mobileno: validation.values.mobileno,
        st_whatsappenabled: st_whatsappenabled,
        st_pwd: validation.values.password,
        st_stsid: 0,
        stid: 0,
        st_preferences: st_categories,       
        st_interested: st_topic
      };

      const response = await axios.post(url.BASE_URL + 'user/saveStudent', data);
      if (response.data.body.statusFlag === 1) {
        validation.resetForm();
        categories.forEach(el => el.checked = false)
        Topic.forEach(el => el.checked = false)
        validation.setFieldValue("selectedLanguage", "");


        // toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        toggleTab(activeTab + 1);
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        // toast.error(response.data.body.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.error("Save Student API Error:", error);
      toast.error("Failed to save student data. Please try again.", { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const handleSelectCategory = (selectedOption) => {
    setselectedLanguage(selectedOption);
  };

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      <div className="account-pages my-5 bg-registerradiant">
        <Container className="registerscreencontainer">
     
          <Row className="justify-content-center Rgisterscreen" >
            <Col md={8} lg={6} xl={6}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle" style={{ backgroundImage: `url(${profileImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                  <Row>
                  {isLoading ?
              <Spinners setLoading={setLoading} /> : 
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <Col xs={7}>
                          <div className="text-primary p-4">
                            <img src={logolight} alt="Trios Logo" style={{ height: "45px", width: "85px", marginLeft: '300%' }} />
                          </div>
                        </Col>
                      </div>
                    </Col>
}
                  </Row>
                </div>
                <CardBody className="pt-0 signupcard">

                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >

                      <Col lg="12">
                        <Card>
                          <CardBody>
                            <h4 className="card-title mb-4">Signup Form</h4>
                            <div className="wizard clearfix">
                              <div className="steps clearfix">
                                <ul>
                                  <NavItem
                                    className={classnames({ current: activeTab === 1 })}
                                  >
                                    <NavLink style={{ fontSize: "11px" }}
                                      className={classnames({ current: activeTab === 1 })}
                                      onClick={() => {
                                        var topicChecked = Topic.filter((e) => e.checked === true)
                                        if(topicChecked.length === 0){
                                          toast.error(REGISTRATIONVARIABLE.LOOKINGFORVALIDATE, { position: toast.POSITION.TOP_RIGHT });
                                          return
                                        }  
                                        var categoryChecked = categories.filter((e) => e.checked === true)
                                        if(categoryChecked.length === 0){
                                          toast.error(REGISTRATIONVARIABLE.PREFERENCEVALIDATE, { position: toast.POSITION.TOP_RIGHT });
                                          return
                                        }   
                                        setactiveTab(1)
                                      }}
                                      disabled={!(passedSteps || []).includes(1)}
                                    >
                                      <span className="number">1.</span> Basic Info
                                    </NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={classnames({ current: activeTab === 2 })}
                                  >
                                    <NavLink style={{ fontSize: "11px" }}
                                      className={classnames({ active: activeTab === 2 })}
                                      onClick={() => {
                                        var topicChecked = Topic.filter((e) => e.checked === true)
                                        if(topicChecked.length === 0){
                                          toast.error(REGISTRATIONVARIABLE.LOOKINGFORVALIDATE, { position: toast.POSITION.TOP_RIGHT });
                                          return
                                        }  
                            
                                        if( validation.values.username == "" || selectedLanguage.value == "" || 
                                        validation.values.email == "" || validation.values.mobileno == "" 
                                      || validation.values.password == ""
                                      ){
                                  
                                        toast.error('Please enter all mandatory field!', { position: toast.POSITION.TOP_RIGHT });
 return
                                      } 
                                        setactiveTab(2)
                                      }}
                                      disabled={!(passedSteps || []).includes(2)}
                                    >
                                      <span className="number">2.</span> Preferences
                                    </NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={classnames({ current: activeTab === 3 })}
                                  >
                                    <NavLink style={{ fontSize: "11px" }}
                                      className={classnames({ active: activeTab === 3 })}
                                      onClick={() => {
                                      
                                        var categoryChecked = categories.filter((e) => e.checked === true)
                                        if(categoryChecked.length === 0){
                                          toast.error(REGISTRATIONVARIABLE.PREFERENCEVALIDATE, { position: toast.POSITION.TOP_RIGHT });
                                          return
                                        }   
                                        if( validation.values.username == "" || selectedLanguage.value == "" || 
                                        validation.values.email == "" || validation.values.mobileno == "" 
                                      || validation.values.password == ""
                                      ){
                                  
                                        toast.error('Please enter all mandatory field!', { position: toast.POSITION.TOP_RIGHT });
                                        return
                                      } 
                                        setactiveTab(3)
                                      }}
                                      disabled={!(passedSteps || []).includes(3)}
                                    >
                                      <span className="number">3.</span> Looking for

                                    </NavLink>
                                  </NavItem>

                                </ul>
                              </div>
                              <div className="content clearfix">
                                <TabContent activeTab={activeTab} className="body">
                                  <TabPane tabId={1}>
                                    <Form>
                                      <Row>
                                        <Col lg="6">
                                          <div className="mb-3">
                                            <Label className="form-label"> Name of the User <span style={{ color: 'red' }}>*</span></Label>
                                            <Input
                                              name="username"
                                              type="text"
                                              placeholder="Enter Name of the user"
                                              autocomplete="off"
                                              onChange={validation.handleChange}
                                              onKeyPress={event => alphOnly(event)}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.username || ""}
                                              invalid={
                                                validation.touched.username && validation.errors.username ? true : false
                                              }
                                              maxLength={30} 
                                            />
                                            {validation.touched.username && validation.errors.username ? (
                                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                        <Col lg="6">
                                          <div className="mb-3">
                                            <Label className="form-label">Email <span style={{ color: 'red' }}>*</span></Label>
                                            <Input
                                              id="email"
                                              name="email"
                                              className="form-control"
                                              placeholder="Enter email"
                                              autocomplete="off"
                                              type="email"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.email || ""}
                                              invalid={
                                                (validation.touched.email && validation.errors.email) || emailNotValid !="" ? true : false
                                              }
                                              maxLength={30} 
                                            />
                                            {emailNotValid && emailNotValid != "" ? (
                                              <FormFeedback type="invalid">{emailNotValid}</FormFeedback>
                                            ) : null}
                                            {validation.touched.email && validation.errors.email ? (
                                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                            ) : null}
                                     
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col lg="6">
                                          <div className="mb-3">
                                            <Label className="form-label">Mobile No.<span style={{ color: 'red' }}>*</span>

                                              <i className="mdi mdi-whatsapp
 text-success display-4 whatsappicon" />

                                              <p
                                                className="form-check form-switch"

                                              >
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input togglealign"
                                                  id="customSwitchsizesm"
                                                  // defaultChecked
                                                // onClick={() => {
                                                //   this.setState({
                                                //     toggleSwitchSize: !this.state
                                                //       .toggleSwitchSize,
                                                //   })
                                                // }}
                                                // onClick={e => {
                                                //   settoggleSwitchSize(!toggleSwitchSize)
                                                // }}
                                                />
                                             
                                              </p>
                                            </Label>
                                            <Input
                                              name="mobileno"
                                              type="text"
                                              placeholder="Enter a Mobile No."
                                              autocomplete="off"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.mobileno || ""}
                                              onKeyPress={event => numberOnly(event)}
                                              invalid={
                                                validation.touched.mobileno && validation.errors.mobileno ? true : false
                                              }
                                              maxLength={10} 
                                            />
                                            {validation.touched.mobileno && validation.errors.mobileno ? (
                                              <FormFeedback type="invalid">{validation.errors.mobileno}</FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                        <Col lg="6">
                                          <div className="mb-3">
                                            <Label className="form-label">Password <span style={{ color: 'red' }}>*</span></Label>
                                            <div className="input-group auth-pass-inputgroup">

                                            <Input
                                              name="password"
                                              value={validation.values.password || ""}
                                              // type="password"
                                              type={passwordShow ? "text" : "password"}
                                              placeholder="Enter Password"
                                              autocomplete="off"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              invalid={validation.touched.password && !!validation.errors.password}
                                              maxLength={30} 
                                            />
                                             <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i>
                                </button>
                                            {validation.touched.password && validation.errors.password ? (
                                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                            ) : null}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col  lg="6">
                                        <div className="mb-3">
                                          <Label>Language<span style={{ color: 'red' }}>*</span></Label>
                                          <Select
                                            value={selectedLanguage}
                                            onChange={(selectedOption) => {

                                              validation.setFieldValue("selectedLanguage", selectedOption);

                                              handleSelectCategory(selectedOption);
                                            }}
                                            options={languageList.map((board) => ({
                                              label: board.l_name,
                                              value: board.lid,
                                            }))}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            is
                                            className={`select2-selection ${validation.touched.selectedLanguage && validation.errors.selectedLanguage ? 'is-invalid' : ''}`}                                            
                                            invalid={
                                              validation.touched.selectedLanguage && validation.errors.selectedLanguage ? true : false
                                            }
                                          />
                                          {validation.touched.selectedLanguage && validation.errors.selectedLanguage ? (
                                            <FormFeedback type="invalid">{validation.errors.selectedLanguage}</FormFeedback>
                                          ) : null}
                                      </div>
                                          {/* <label className="col-md-2 col-form-label">Language</label>
                                          <div className="col-md-10">
                                            <select className="form-control">
                                              <option>Select</option>
                                              <option>Large select</option>
                                              <option>Small select</option>
                                            </select>
                                          </div> */}
                                        </Col>
                                      </Row>

                                    </Form>
                                  </TabPane>
                                  <TabPane tabId={2}>
                                    <div>
                                      <Form>
                                        {categories.map(category => (
                                          <Row key={category.exctid}>
                                            <Col lg="6">
                                              <div className="mb-3">
                                                <Label check>
                                                  <Input
                                                    type="checkbox"
                                                    name={`category-${category.exctid}`}
                                                    // checked={validation.values.tnpsc}
                                                    value={category.exctid}

                                                    checked={category.checked}
                                                    onClick={(e) => checkhandlePerference(category)}
                                                    // onChange={handleChange}
                                                  />{' '}
                                                  {category.exboard_exboardcategory}
                                                </Label>
                                              </div>
                                            </Col>
                                          </Row>
                                        ))}
                                      </Form>
                                    </div>
                                  </TabPane>
                                  <TabPane tabId={3}>
                                    <div>
                                      <Form>
                                        {Topic.map(Toplist => (
                                          <Row key={Toplist.mstid}>
                                            <Col lg="6">
                                              <div className="mb-3">
                                                <Label check>
                                                  <Input
                                                    type="checkbox"
                                                    name={`Toplist-${Toplist.mstid}`}
                                                    checked={Toplist.checked}
                                                    // onChange={handleChange}
                                                    onClick={(e) => checkhandleTopic(Toplist)}
                                                  />{' '}
                                                  {Toplist.mst_displaytext}
                                                </Label>
                                              </div>
                                            </Col>
                                          </Row>
                                        ))}

                                        {/* <Row>
                                          <ReCAPTCHA
                                            sitekey="6LdekI8pAAAAAPz-IcmXQlE9Z8NyHPhmEdgwWiaJ"
                                            onChange={(value) => {
                                              console.log("reCAPTCHA value:", value);

                                            }}
                                          />
                                        </Row> */}

                                      </Form>
                                    </div>
                                  </TabPane>

                                  <TabPane tabId={4}>
                                    <div className="row justify-content-center">
                                      <Col lg="8">
                                        <div className="text-center">
                                          <div className="mb-4">
                                            <i className="mdi mdi-check-circle-outline text-success display-4" />
                                            <h5>Register Successfully</h5>
                                            <p className="font-size-12">To complete registration process check your email</p>
                                          </div>



                                        </div>
                                      </Col>
                                    </div>
                                  </TabPane>
                                </TabContent>
                              </div>
                              <div className="actions clearfix">
                                <ul>
                                  {activeTab !== 1 && activeTab !== 4 && (
                                    <li className="previous">
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          toggleTab(activeTab - 1);
                                        }}
                                      >
                                        Previous
                                      </Link>
                                    </li>
                                  )}
                                  {activeTab !== 4 && (
                                    <li className="next">
                                      {/* {
                                      isLoading ? 
                                      
                                      <Button type="button" color="primary" disabled>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                        Validating
                                      </Button> 
                                      
                                      
                                      
                                      :  */}
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          if (activeTab === 3) {

                                            handleSave();
                                          } 
                                          else if(activeTab == 1){
                                            if( validation.values.username == "" || selectedLanguage.value == "" || 
                                            validation.values.email == "" || validation.values.mobileno == "" 
                                          || validation.values.password == ""
                                          ){

                                            toast.error('Please enter all mandatory field!', { position: toast.POSITION.TOP_RIGHT });
                                          }
                                      

                                            checkMobileEmail()
                                          }
                                          
                                          else {
                                            toggleTab(activeTab + 1);
                                          }
                                        }}
                                      >
                                        {activeTab === 3 ? "Save" : "Next"}
                                      </Link>
                                      {/* }  */}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      {/* {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                    

                    
                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div> */}

                      <div className="mt-4 text-center">
                        <p>
                          Already have an account ?{" "} <Link to="/login" className="font-weight-medium text-primary">
                            {" "}
                            Sign in
                          </Link>{" "}
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
};

export default Register;